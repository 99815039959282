/* global gtag */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Grid,
  Typography,
  Box,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useCustomTheme from "../../hooks/useCustomTheme";
import Layout from "../../components/layout";

const solutionTypes = [
  {
    value: "small-business",
    label: "Small Business",
    description:
      "I am a small business looking to integrate a booking solution on my website. I'm looking for something quick and easy.",
  },
  {
    value: "large-business",
    label: "Large Business",
    description:
      "I am a large business looking to develop a custom booking solution. I'm looking for a self-hosted or dedicated hosting solution.",
  },
  {
    value: "developer",
    label: "Developer",
    description:
      "I am a developer looking to integrate a booking solution within an application. I'm looking for something that I can customize to my specific needs.",
  },
];

const validationSchema = yup.object({
  solutionType: yup
    .string()
    .required("Solution type is required")
    .oneOf(["small-business", "large-business", "developer"]),
});

const IntroUse = () => {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const loc = useLocation();
  const sessionToken = loc.state && loc.state.sessionToken ? loc.state.sessionToken : undefined;
  const nickName = loc.state && loc.state.nickName ? loc.state.nickName : undefined;

  const navigate = useNavigate();
  const theme = useCustomTheme();

  useEffect(() => {
    if (sessionToken === undefined) {
      navigate("/login", { state: { redirectTo: "/intro/use" } });
    }
  }, [sessionToken]);

  const handleChange = (event) => {
    formik.setFieldValue("solutionType", event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      solutionType: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setErrors([]);
      try {
        //TODO: Add the logic to save the solution type in the backend
        const saveBusinessInfoResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/account/saveBussinesInfo?session_token=${sessionToken}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              nick_name: nickName,
              solution_type: values.solutionType,
            }),
          }
        );

        if (saveBusinessInfoResponse.ok) {
          // Ensure gtag is defined
          // @ts-ignore
          if (typeof gtag !== "undefined") {
            // @ts-ignore
            gtag("event", "signup_solution", {
              event_category: "signup",
              event_label: "Signup Solution",
              type: values.solutionType,
            });
          }
          navigate("/intro/start", {
            state: { nickName: nickName, sessionToken: sessionToken },
          });
        } else {
          const error = {
            type: "error",
            message:
              "There was an error saving the business information. Please try again.",
          };
          setErrors([error]);
        }
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  return (
    <Layout
      errors={errors}
      itemsSx={{
        backgroundColor: "rgba(255,255,255)",
        width: "800px",
      }}
      contentGridSx={{
        padding: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(10, 5),
        },
      }}
    >
      <Helmet>
        <title>Setster - Custom Booking Solutions for Every Need</title>
        <meta
          name="description"
          content="Setster provides custom booking solutions tailored for small businesses, large businesses, and developers. Choose the right option for your needs and integrate an efficient booking system seamlessly."
        />
        <meta
          name="keywords"
          content="booking solutions, small business booking, large business booking, developer booking solution, custom booking integration, Setster"
        />
        <meta name="author" content="Setster" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta
          property="og:title"
          content="Setster - Custom Booking Solutions for Every Need"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.setster.com" />
        <meta property="og:site_name" content="Setster" />
        <meta
          property="og:description"
          content="Setster provides custom booking solutions tailored for small businesses, large businesses, and developers. Choose the right option for your needs and integrate an efficient booking system seamlessly."
        />
        <meta property="og:author" content="Setster" />
        <meta
          property="og:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta property="og:image:width" content="280" />
        <meta property="og:image:height" content="90" />
        <meta
          name="twitter:title"
          content="Setster - Custom Booking Solutions for Every Need"
        />
        <meta
          name="twitter:description"
          content="Setster provides custom booking solutions tailored for small businesses, large businesses, and developers. Choose the right option for your needs and integrate an efficient booking system seamlessly."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Setster" />
        <meta name="twitter:url" content="https://www.setster.com" />
        <meta
          name="twitter:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta name="twitter:image:width" content="280" />
        <meta name="twitter:image:height" content="90" />
        <link
          rel="alternate"
          href="https://www.setster.com/intro/use"
          // @ts-ignore
          hreflang="en"
        />
        <link rel="canonical" href="https://www.setster.com/intro/use" />
      </Helmet>

      <Typography
        component="h1"
        variant="h5"
        sx={{ margin: theme.spacing(0, 0, 3) }}
        textAlign="left"
      >
        What type of solution are you looking for?
      </Typography>
      <form
        style={{
          width: "100%",
          marginTop: "50px",
          margin: theme.spacing(3),
        }}
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              error={
                formik.touched.solutionType &&
                Boolean(formik.errors.solutionType)
                  ? true
                  : false
              }
            >
              <RadioGroup
                aria-labelledby="what-solution-type"
                name="solution-type"
                value={formik.values.solutionType}
                onChange={handleChange}
              >
                {solutionTypes.map((solType) => {
                  return (
                    <FormControlLabel
                      key={solType.value}
                      value={solType.value}
                      control={<Radio />}
                      label={
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            borderLeft: "1px solid #ccc",
                            padding: "20px",
                          }}
                        >
                          <Typography variant="body1">
                            {solType.label}
                          </Typography>
                          <Typography variant="body2">
                            {solType.description}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        borderRadius: "8px",
                        border: "2px solid #ccc",
                        padding: "0",
                        margin: "0 0 20px 0",
                        "&:hover": {
                          border: `2px solid ${theme.palette.primary.main}`,
                        },
                      }}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <LoadingButton
            type="submit"
            variant="text"
            color="primary"
            loading={loading}
            loadingPosition="start"
            startIcon={<i className="fas fa-spinner fa-spin"></i>}
            sx={{
              margin: theme.spacing(3, 0, 2),
            }}
            disabled={
              Object.keys(formik.errors).length > 0 ||
              formik.values.solutionType === ""
            }
            size="large"
          >
            Next <NavigateNextIcon fontSize="small" />
          </LoadingButton>
        </Grid>
      </form>
    </Layout>
  );
};

export default IntroUse;
