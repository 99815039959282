import React from "react";
import { styled } from "@mui/material/styles";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import setsterLogo from "./../../images/setster-logo.png";
import { ThemeProvider, Typography } from "@mui/material";
import useCustomTheme from "../../hooks/useCustomTheme";
import SetsterLogo from "../../components/setsterLogo";

const StyledCheck = styled(CheckCircleRoundedIcon)({
  // color: "#D0021B"
  color: "#635BFF",
  // color: "#000"
});

const PerksList = () => {
  const theme = useCustomTheme();
  return (
    <ThemeProvider theme={theme}>
      <div>
        <SetsterLogo src={setsterLogo} alt="Setster Logo" />
        <Typography
          component="h2"
          variant="h5"
          sx={{ margin: theme.spacing(0, 0, 3) }}
          textAlign="left"
        >
          ALL FEATURES INCLUDED
        </Typography>
        <Typography
          component="h2"
          variant="h6"
          sx={{ margin: theme.spacing(0, 0, 3) }}
          textAlign="left"
        >
          Free 15 day trial of our Developer plan! No credit card required.
        </Typography>

        <List>
          <ListItem alignItems="center">
            <ListItemIcon>
              <StyledCheck />
            </ListItemIcon>
            <ListItemText primary="Instantly schedule appointments effortlessly." />
          </ListItem>
          <ListItem alignItems="center">
            <ListItemIcon>
              <StyledCheck />
            </ListItemIcon>
            <ListItemText primary="Use APIs, low-code solutions, or widgets." />
          </ListItem>
          <ListItem alignItems="center">
            <ListItemIcon>
              <StyledCheck />
            </ListItemIcon>
            <ListItemText primary="Easy integration with third-party tools." />
          </ListItem>
          <ListItem alignItems="center">
            <ListItemIcon>
              <StyledCheck />
            </ListItemIcon>
            <ListItemText primary="24/7 stellar customer support." />
          </ListItem>
        </List>
      </div>
    </ThemeProvider>
  );
};

export default PerksList;
