import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Stack,
  InputAdornment,
  IconButton,
  Link,
  CircularProgress,
  Box
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InfoIcon from "@mui/icons-material/Info";
import useCustomTheme from "../../hooks/useCustomTheme";
import Layout from "../../components/layout";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);
  const [hashVerificationLoading, setHashVerificationLoading] =
    React.useState(false);
  const [emailAddress, setEmailAddress] = React.useState(searchParams.get("email"));
  const [hashCode, setHashCode] = React.useState(searchParams.get("hash_code"));
  const [passwordReset, setPasswordReset] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [recaptchaToken, setRecaptchaToken] = React.useState(undefined);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [hashIsValid, setHashIsValid] = React.useState(false);
  const [hashVerified, setHashVerified] = React.useState(false);
  const theme = useCustomTheme();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password criteria not met"
      ),
    confirmpassword: yup
      .string()
      .required("Password confirmation required")
      .oneOf([yup.ref("password")], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmpassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setErrors([]);
      setLoading(true);

      const resp = await handlePasswordReset(values);
      if (resp.ok) {
        setLoading(false);
        const respJson = await resp.json();
        if (respJson.data && respJson.data.reset && respJson.data.reset === true) {
          setPasswordReset(true);
        } else {
          const error = {
            type: "error",
            message: "Password reset failed",
          };
          setErrors([error]);
        }
      } else {
        const respJson = await resp.json();
        const error = {
          type: "error",
          message: respJson.statusDescription,
        };
        setErrors([error]);
        setLoading(false);
      }
    },
  });

  const verifyHash = async () => {
    const parsedData = {
      email: emailAddress,
      hash_code: hashCode,
      recaptcha_token: recaptchaToken,
    };

    try {
      const resp = await fetch(
        `${process.env.REACT_APP_API_URL}/account/CheckResetPasswordHash`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(parsedData),
        }
      );

      return resp;
    }
    catch (error) {
      const errorMsg = {
        type: "error",
        message: error.message,
      };
      setErrors([errorMsg]);
    }
  };

  useEffect(() => {
    setHashVerificationLoading(true);
    const verify = async () => {
      if (recaptchaToken !== undefined) {
        const resp = await verifyHash();
        await handleReCaptchaVerify();
        setHashVerified(true);
        if (resp.ok) {
          setHashVerificationLoading(false);
          setHashIsValid(true);
        } else {
          const respJson = await resp.json();
          if (
            respJson.statusDescription === "Invalid hash code" &&
            respJson.statusCode === 1
          ) {
            setHashVerificationLoading(false);
            setHashIsValid(false);
          } else {
            setHashVerificationLoading(false);
            const error = {
              type: "error",
              message: respJson.statusDescription,
            };
            setErrors([error]);
          }
        }
      }
    };

    if (hashIsValid === false && hashVerified === false) {
      verify();
    } else {
      setHashVerificationLoading(false);
    }
  }, [hashVerified, hashIsValid, recaptchaToken]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("reset_password_page");
    if (token) {
      setRecaptchaToken(token);
      return true;
    }

    return false;
  }, [executeRecaptcha]);

  const handlePasswordReset = async (values) => {
    const parsedData = {
      email: emailAddress,
      hash_code: hashCode,
      new_password: values.password,
      recaptcha_token: recaptchaToken,
    };

    try {
      const resendReponse = await fetch(
        `${process.env.REACT_APP_API_URL}/account/ResetAccountPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(parsedData),
        }
      );

      return resendReponse;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <Layout
      errors={errors}
      itemsSx={{ width: { xs: "100%", sm: "600px", md: "600px", lg: "600px" } }}
      contentGridSx={{
        padding: {
                xs: theme.spacing(5),
                sm: theme.spacing(5),
                md: theme.spacing(10),
                lg: theme.spacing(10),
              },
      }}
    >
      <Helmet>
        <title>Setster Password Recovery | Create a New Password</title>
        <meta
          name="description"
          content="Securely set up a new password for your Setster account."
        />
        <meta name="author" content="Setster" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta
          property="og:title"
          content="Setster Password Recovery | Create a New Password"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.setster.com" />
        <meta property="og:site_name" content="Setster" />
        <meta
          property="og:description"
          content="Securely set up a new password for your Setster account."
        />
        <meta property="og:author" content="Setster" />
        <meta
          property="og:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta property="og:image:width" content="280" />
        <meta property="og:image:height" content="90" />
        <meta
          name="twitter:title"
          content="Setster Password Recovery | Create a New Password"
        />
        <meta
          name="twitter:description"
          content="Securely set up a new password for your Setster account."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Setster" />
        <meta name="twitter:url" content="https://www.setster.com" />
        <meta
          name="twitter:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta name="twitter:image:width" content="280" />
        <meta name="twitter:image:height" content="90" />
        <link
          rel="alternate"
          href="https://www.setster.com/reset-password"
        />
        <link rel="canonical" href="https://www.setster.com/reset-password" />
      </Helmet>

      {hashVerificationLoading === true ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : hashIsValid !== true ? (
        <>
          <Typography
            component="h1"
            variant="h5"
            textAlign="left"
            sx={{ margin: theme.spacing(0, 0, 2, 0) }}
          >
            Invalid password reset link
          </Typography>
          <Typography variant="body2">
            Either the password reset link has expired or the hash code is
            not valid.{" "}
            <Link href="/forgot-password">Please try again.</Link>
          </Typography>
        </>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <>
            {passwordReset !== true ? (
              <>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ margin: theme.spacing(0, 0, 3) }}
                  textAlign="left"
                >
                  Update Your Password
                </Typography>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      height: {
                        xs: "90px",
                        sm: "70px",
                        md: "70px",
                        lg: "70px",
                        xl: "70px",
                        xxl: "70px",
                        xxxl: "70px",
                      },
                    }}
                  >
                    <TextField
                      size="small"
                      variant="outlined"
                      id="email"
                      required
                      fullWidth={true}
                      name="email"
                      label="Email"
                      autoComplete="email"
                      autoFocus={true}
                      value={emailAddress}
                      disabled={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      height: {
                        xs: "90px",
                        sm: "70px",
                        md: "70px",
                        lg: "70px",
                        xl: "70px",
                        xxl: "70px",
                        xxxl: "70px",
                      },
                    }}
                  >
                    <TextField
                      size="small"
                      variant="outlined"
                      id="password"
                      required
                      fullWidth={true}
                      name="password"
                      label="New Password"
                      autoComplete="current-password"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      // helperText={formik.touched.password && formik.errors.password}
                      helperText={formik.errors.password}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      height: {
                        xs: "90px",
                        sm: "70px",
                        md: "70px",
                        lg: "70px",
                        xl: "70px",
                        xxl: "70px",
                        xxxl: "70px",
                      },
                    }}
                  >
                    <TextField
                      size="small"
                      variant="outlined"
                      id="confirmpassword"
                      required
                      fullWidth={true}
                      name="confirmpassword"
                      label="Confirm Password"
                      autoComplete="current-password"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values.confirmpassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.confirmpassword &&
                        Boolean(formik.errors.confirmpassword)
                      }
                      helperText={formik.errors.confirmpassword}
                    />
                  </Grid>
                </Grid>
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ margin: theme.spacing(3, 0, 3) }}
                >
                  <InfoIcon color="primary" />
                  <Typography component="h5" variant="body2">
                    Password must be at least 8 characters long and have
                    one uppercase, lowercase, number, and special
                    character
                  </Typography>
                </Stack>
                <Stack spacing={2} direction="row">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<i className="fas fa-spinner fa-spin"></i>}
                    disableElevation={true}
                    disabled={
                      Object.keys(formik.touched).length === 0 ||
                      Object.keys(formik.errors).length > 0
                    }
                    size="large"
                    fullWidth={true}
                    sx={{
                      margin: theme.spacing(3, 0, 2),
                      backgroundColor: "#000",
                      "&:hover": {
                        backgroundColor: "#635BFF",
                      },
                    }}
                  >
                    Continue
                  </LoadingButton>
                </Stack>
              </>
            ) : (
              <>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ margin: theme.spacing(0, 0, 5, 0) }}
                  textAlign="left"
                >
                  Your password was successfully reset!
                </Typography>
                <LoadingButton
                  type="button"
                  variant="contained"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<i className="fas fa-spinner fa-spin"></i>}
                  disableElevation={true}
                  disabled={
                    Object.keys(formik.touched).length === 0 ||
                    Object.keys(formik.errors).length > 0
                  }
                  size="large"
                  fullWidth={true}
                  sx={{
                    margin: theme.spacing(3, 0, 2),
                    backgroundColor: "#000",
                    "&:hover": {
                      backgroundColor: "#635BFF",
                    },
                  }}
                  href="/login"
                >
                  Login
                </LoadingButton>
              </>
            )}
          </>
        </form>
      )}
    </Layout>
  );
};

export default ResetPassword;
