/* global gtag */
import React, { useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
  InputAdornment,
  IconButton,
  Grid,
  Typography,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as yup from "yup";
import { useSearchParams } from "react-router-dom";
import useCustomTheme from "../../hooks/useCustomTheme";
import Layout from "../../components/layout";

const EmailConfirmed = () => {
  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = React.useState(false);
  const email = searchParams.get("email");
  const hash = searchParams.get("hash");
  const firstName = searchParams.get("first_name") || "";
  const [recaptchaToken, setRecaptchaToken] = React.useState(undefined);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const navigate = useNavigate();
  const theme = useCustomTheme();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("signup_page");
    if (token) {
      setRecaptchaToken(token);
      return true;
    }

    return false;
  }, [executeRecaptcha]);

  const loginSchema = yup.object().shape({
    password: yup.string().required("Password is required"),
  });

  const login = async (values, onSubmitProps) => {
    try {
      const parsedData = {
        email: email,
        password: values.password,
        recaptcha_token: recaptchaToken,
        action: "login",
      };

      const authResp = await fetch(
        `${process.env.REACT_APP_API_URL}/account/authenticate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(parsedData),
        }
      );

      return authResp;
    } catch (error) {
      console.error(error);
      formik.setErrors({
        password: "Invalid password for the confirmed email",
      });
    }
  };

  const verifyEmail = async () => {
    try {
      const parsedData = {
        email: email,
        hash: hash,
        recaptcha_token: recaptchaToken,
      };

      const verifyEmailReponse = await fetch(
        `${process.env.REACT_APP_API_URL}/account/verifyEmail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(parsedData),
        }
      );

      return verifyEmailReponse;
    } catch (error) {
      console.error(error);
      const errorMsg = {
        type: "error",
        message: error.message,
      };
      setErrors([errorMsg]);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setErrors([]);
      const resp = await login(values);
      const respJson = await resp.json();
      if (resp.ok) {
        // Ensure gtag is defined
        // @ts-ignore
        if (typeof gtag !== "undefined") {
          // @ts-ignore
          gtag("event", "signup_verified_login", {
            event_category: "signup",
            event_label: "Email Verified Login",
          });
        }
        setLoading(false);
        if (respJson.data && respJson.data.session_token !== undefined) {
          navigate("/intro", {
            state: { sessionToken: respJson.data.session_token },
          });
        } else {
          const error = {
            type: "error",
            message: "Could not login account",
          };
          setErrors([error]);
          setLoading(false);
        }
      } else {
        if (resp.status === 401) {
          const error = {
            type: "error",
            message:
              "Incorrect password. Try again or click forgot password to reset it.",
          };
          setErrors([error]);
        } else {
          const error = {
            type: "error",
            message: respJson.statusDescription,
          };
          setErrors([error]);
        }
        setLoading(false);
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    const verify = async () => {
      if (recaptchaToken !== undefined) {
        const resp = await verifyEmail();
        if (resp.ok) {
          // Ensure gtag is defined
          // @ts-ignore
          if (typeof gtag !== "undefined") {
            // @ts-ignore
            gtag("event", "signup_verified", {
              event_category: "signup",
              event_label: "Email Verified",
            });
          }
          const message = {
            type: "success",
            message: "Email verified",
          };
          setErrors([message]);
        } else {
          const respJson = await resp.json();
          const error = {
            type: "error",
            message: respJson.statusDescription,
          };
          setErrors([error]);
        }
      }
    };

    verify();
  }, [recaptchaToken]);

  return (
    <Layout
      errors={errors}
      contentGridSx={{
        margin: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(2),
        },
      }}
    >
      <Helmet>
        <title>Your Email is Verified</title>
        <meta
          name="description"
          content="Your email has been successfully verified."
        />
        <meta name="author" content="Setster" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta name="robots" content="noindex" />
        <meta property="og:title" content="Your Email is Verified" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.setster.com" />
        <meta property="og:site_name" content="Setster" />
        <meta
          property="og:description"
          content="Your email has been successfully verified."
        />
        <meta property="og:author" content="Setster" />
        <meta
          property="og:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta property="og:image:width" content="280" />
        <meta property="og:image:height" content="90" />
        <meta name="twitter:title" content="Your Email is Verified" />
        <meta
          name="twitter:description"
          content="Your email has been successfully verified."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Setster" />
        <meta name="twitter:url" content="https://www.setster.com" />
        <meta
          name="twitter:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta name="twitter:image:width" content="280" />
        <meta name="twitter:image:height" content="90" />
        <link rel="alternate" href="https://www.setster.com/email-confirmed" />
        <link rel="canonical" href="https://www.setster.com/email-confirmed" />
      </Helmet>

      <Typography
        component="h1"
        variant="h5"
        sx={{ margin: theme.spacing(0, 0, 3) }}
        textAlign="left"
      >
        Great {firstName}, let’s log you in!
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                id="password"
                required
                fullWidth={true}
                name="password"
                label="Password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                sx={{ margin: theme.spacing(6, 0, 3) }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                // helperText={formik.touched.password && formik.errors.password}
                helperText={
                  formik.errors.password
                    ? formik.errors.password
                    : "Confirm your password to start onboarding"
                }
              />
            </Grid>
          </Grid>
          <Stack
            spacing={2}
            direction="row"
            sx={{ margin: theme.spacing(1, 0, 3) }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              loadingPosition="start"
              startIcon={<i className="fas fa-spinner fa-spin"></i>}
              disableElevation={true}
              disabled={formik.values.password.length < 1}
              size="large"
              fullWidth={true}
              sx={{
                margin: theme.spacing(3, 0, 2),
                backgroundColor: "#000",
                "&:hover": {
                  backgroundColor: "#635BFF",
                },
              }}
            >
              Start
            </LoadingButton>
          </Stack>
        </>
      </form>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ margin: theme.spacing(1, 0, 3) }}
      >
        <Link href="/forgot-password" variant="body2" color="primary">
          Forgot password
        </Link>
      </Stack>
    </Layout>
  );
};

export default EmailConfirmed;
