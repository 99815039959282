/* global gtag */
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Link,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import useCustomTheme from "../../hooks/useCustomTheme";
import Layout from "../../components/layout";

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [recaptchaToken, setRecaptchaToken] = React.useState(undefined);
  const [sessionToken, setSessionToken] = React.useState(undefined);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const loc = useLocation();
  const navigate = useNavigate();
  const redirectTo =
    loc.state && loc.state.redirectTo ? loc.state.redirectTo : undefined;
  const theme = useCustomTheme();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getAccountInfo = async (sessionToken) => {
    try {
      const accountInfoResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/account?session_token=${sessionToken}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (accountInfoResponse.ok) {
        const accountInfoResponseJson = await accountInfoResponse.json();
        return accountInfoResponseJson;
      } else {
        console.error("Error fetching account info");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email",
      ),
    password: yup.string().required("Password is required"),
  });

  const login = async (values) => {
    try {
      const parsedData = {
        email: values.email,
        password: values.password,
        recaptcha_token: recaptchaToken,
        action: "login",
      };

      const authResp = await fetch(
        `${process.env.REACT_APP_API_URL}/account/authenticate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(parsedData),
        },
      );

      return authResp;
    } catch (error) {
      console.error(error);
      formik.setErrors({
        password: "Invalid password for the confirmed email",
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      handleReCaptchaVerify();
      setErrors([]);
      const resp = await login(values);
      const respJson = await resp.json();
      if (resp.ok) {
        // Ensure gtag is defined
        // @ts-ignore
        if (typeof gtag !== "undefined") {
          // @ts-ignore
          gtag("event", "login_success", {
            event_category: "login",
            event_label: "Login Success",
          });
        }
        setLoading(false);
        if (respJson.data && respJson.data.session_token !== undefined) {
          const accountInfo = await getAccountInfo(respJson.data.session_token);
          if (
            accountInfo &&
            accountInfo.data &&
            (accountInfo.data.company_name === null ||
              accountInfo.data.company_name === "")
          ) {
            navigate("/intro", {
              state: { sessionToken: respJson.data.session_token },
            });
          } else {
            if (redirectTo) {
              navigate(redirectTo, {
                state: { sessionToken: respJson.data.session_token },
              });
            } else {
              const url = `https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/admin`;
              // @ts-ignore
              window.location = url;
            }
          }
        } else {
          const error = {
            type: "error",
            message: "Could not login account",
          };
          setErrors([error]);
          setLoading(false);
        }
      } else {
        const error = {
          type: "error",
          message: respJson.statusDescription,
        };
        setErrors([error]);
        setLoading(false);
      }
    },
  });

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("signup_page");
    if (token) {
      setRecaptchaToken(token);
      return true;
    }

    return false;
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <Layout
      errors={errors}
      itemsSx={{
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, 1),
        },
      }}
      contentGridSx={{
        [theme.breakpoints.up("sm")]: {
          margin: theme.spacing(0, 4),
        },
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, 2),
        },
      }}
    >
      <Helmet>
        <title>Setster Login | Sign in to Your Setster Account</title>
        <meta
          name="description"
          content="Sign in to your Setster account to manage appointments, view schedules, and access feature settings."
        />
        <meta name="author" content="Setster" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta
          property="og:title"
          content="Setster Login | Sign in to Your Setster Account"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.setster.com" />
        <meta property="og:site_name" content="Setster" />
        <meta
          property="og:description"
          content="Sign in to your Setster account to manage appointments, view schedules, and access feature settings."
        />
        <meta property="og:author" content="Setster" />
        <meta
          property="og:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta property="og:image:width" content="280" />
        <meta property="og:image:height" content="90" />
        <meta
          name="twitter:title"
          content="Setster Login | Sign in to Your Setster Account"
        />
        <meta
          name="twitter:description"
          content="Sign in to your Setster account to manage appointments, view schedules, and access feature settings."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Setster" />
        <meta name="twitter:url" content="https://www.setster.com" />
        <meta
          name="twitter:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta name="twitter:image:width" content="280" />
        <meta name="twitter:image:height" content="90" />
        <link rel="alternate" href="https://www.setster.com/login" />
        <link rel="canonical" href="https://www.setster.com/login" />
      </Helmet>
      <Typography
        component="h1"
        variant="h5"
        sx={{ margin: theme.spacing(0, 0, 3) }}
        textAlign="left"
      >
        Log into your Setster account
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                height: {
                  xs: "90px",
                  sm: "70px",
                  md: "70px",
                  lg: "70px",
                  xl: "70px",
                  xxl: "70px",
                  xxxl: "70px",
                },
              }}
            >
              <TextField
                size="small"
                variant="outlined"
                id="email"
                required
                fullWidth={true}
                name="email"
                label="Email"
                autoComplete="email"
                autoFocus={true}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                height: {
                  xs: "90px",
                  sm: "70px",
                  md: "70px",
                  lg: "70px",
                  xl: "70px",
                  xxl: "70px",
                  xxxl: "70px",
                },
              }}
            >
              <TextField
                size="small"
                variant="outlined"
                id="password"
                required
                fullWidth={true}
                name="password"
                label="Password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                sx={{ margin: theme.spacing(0, 0, 3) }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
          </Grid>
          <Stack
            spacing={2}
            direction="row"
            sx={{ margin: theme.spacing(1, 0, 3) }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              loadingPosition="start"
              startIcon={<i className="fas fa-spinner fa-spin"></i>}
              disableElevation={true}
              disabled={formik.values.password.length < 1}
              size="large"
              fullWidth={true}
              sx={{
                margin: theme.spacing(3, 0, 2),
                backgroundColor: "#000",
                "&:hover": {
                  backgroundColor: "#635BFF",
                },
              }}
            >
              Login
            </LoadingButton>
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ margin: theme.spacing(1, 0, 3) }}
          >
            <Link
              href="/forgot-password"
              variant="body2"
              color="primary"
              sx={{ textDecoration: "underline" }}
            >
              Forgot password
            </Link>
            <Link
              href="/signup"
              variant="body2"
              color="primary"
              sx={{ textDecoration: "underline" }}
              onClick={() => {
                // Ensure gtag is defined
                // @ts-ignore
                if (typeof gtag !== "undefined") {
                  // @ts-ignore
                  gtag("event", "login_signup", {
                    event_category: "signup",
                    event_label: "Login Signup",
                  });
                }
              }}
            >
              Create an account
            </Link>
          </Stack>
        </>
      </form>
    </Layout>
  );
};

export default Login;
