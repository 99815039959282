/* global gtag */
import React, { useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  InputAdornment,
  IconButton,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
  Container,
  FormControl,
  ThemeProvider,
  FormHelperText,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { createTheme, styled } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InfoIcon from "@mui/icons-material/Info";
import setsterLogo from "./../../images/setster-logo.png";
import { useFormik } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as yup from "yup";
import ErrorComponent from "../../components/error";
import queryString from "query-string";

const theme = createTheme({
  // @ts-ignore
  "@global": {
    body: {
      backgroundColor: `palette.common.white`,
    },
  },
  palette: {
    primary: {
      main: "#635BFF",
    },
    action: {
      hover: "#635BFF",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

const StyledFormLogo = styled("img")(({ theme }) => ({
  width: "100px",
  margin: theme.spacing(0, 0, 3),
  paddingRight: "10px", // Default value for padding-right
  paddingBottom: "10px", // Default value for padding-bottom

  [theme.breakpoints.up("sm")]: {
    paddingRight: "10px", // padding-right for sm and up
    paddingBottom: "15px", // padding-bottom for sm and up
  },
  [theme.breakpoints.up("md")]: {
    paddingRight: "0px", // padding-right for md and up
  },
  [theme.breakpoints.up("lg")]: {
    paddingBottom: "15px", // padding-bottom for lg and up
  },
  [theme.breakpoints.up("xl")]: {
    paddingBottom: "15px", // padding-bottom for xl and up
  },
}));

const StyledContainerDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "5px",
  justifyContent: "flex-start",
});
///[a-zA-Z]/
const validationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email",
    )
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password criteria not met",
    ),
  termsOfService: yup
    .boolean()
    .required("You must accept the Terms of Service to proceed")
    .oneOf([true], "You must accept the Terms of Service to proceed"),
  // .matches(true, "Error")
});

const SignUpForm = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [loading, setLoading] = React.useState(false);
  const [recaptchaToken, setRecaptchaToken] = React.useState(undefined);
  const [errors, setErrors] = React.useState([]);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const loc = useLocation();
  const coupon = loc.search ? queryString.parse(loc.search).coupon : null;

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("signup_page");
    if (token) {
      setRecaptchaToken(token);
      return true;
    }

    return false;
  }, [executeRecaptcha]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const signup = async (values) => {
    setLoading(true);
    handleReCaptchaVerify();

    const parsedData = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      // Default time to eastern. They should update it in the onboarding flow
      timezone_id: 548,
      timezone: -240,
      recaptcha_token: recaptchaToken,
      request_location: "signup",
      created_from: "signup",
      coupon: coupon,
    };

    try {
      const signupResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/account/savePersonalInfo`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(parsedData),
        },
      );

      if (signupResponse.ok) {
        // Ensure gtag is defined
        // @ts-ignore
        if (typeof gtag !== "undefined") {
          // @ts-ignore
          gtag("event", "signup_start", {
            event_category: "signup",
            event_label: "Signup Started",
          });
        }
        setLoading(false);
        navigate("/verify-email", {
          state: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
          },
        });
      } else {
        setLoading(false);
        const resp = await signupResponse.json();
        console.log(resp);
        setErrors([
          {
            type: "error",
            message: resp.statusDescription,
          },
        ]);
      }
    } catch (error) {
      /**
       * REPLACE WITH CORRECT ERROR HANDLING
       */
      console.log(error);
      formik.setErrors({
        password: "Invalid password for the confirmed email",
      });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      termsOfService: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await signup(values);
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <StyledContainerDiv
          sx={{
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(8),
          }}
        >
          <StyledFormLogo
            src={setsterLogo}
            alt="Setster Logo"
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          />
          <Typography component="h1" variant="h5" textAlign="left">
            Create Your Setster Account
          </Typography>
          <form
            style={{
              width: "100%",
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} sx={{ marginBottom: "20px" }}>
                <ErrorComponent errors={errors} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  height: {
                    xs: "70px",
                    sm: "70px",
                    md: "70px",
                    lg: "70px",
                    xl: "70px",
                    xxl: "70px",
                    xxxl: "70px",
                  },
                }}
              >
                <TextField
                  size="small"
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  height: {
                    xs: "70px",
                    sm: "70px",
                    md: "70px",
                    lg: "70px",
                    xl: "70px",
                    xxl: "70px",
                    xxxl: "70px",
                  },
                }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  height: {
                    xs: "70px",
                    sm: "70px",
                    md: "70px",
                    lg: "70px",
                    xl: "70px",
                    xxl: "70px",
                    xxxl: "70px",
                  },
                }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  // name="email"
                  autoComplete="email"
                  name="email"
                  // label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  height: {
                    xs: "70px",
                    sm: "70px",
                    md: "70px",
                    lg: "70px",
                    xl: "70px",
                    xxl: "70px",
                    xxxl: "70px",
                  },
                }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  id="password"
                  required
                  fullWidth={true}
                  name="password"
                  label="Password"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="agreeToTerms"
                        color="primary"
                        id="termsOfService"
                        required
                        onChange={formik.handleChange}
                        // onChange={(e) => {
                        //   formik.setFieldValue(
                        //     "termsOfService",
                        //     e.target.checked
                        //   );
                        // }}
                        name="termsOfService"
                        checked={formik.values.termsOfService}
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle2"
                        component="span"
                        sx={{ textAlign: "left", fontSize: "12px" }}
                      >
                        {"By signing up, you agree to our "}
                        <Link href="/legal/terms">terms of service</Link>
                      </Typography>
                    }
                    sx={{ textAlign: "left" }}
                    required={true}
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {formik.touched.termsOfService &&
                    formik.errors.termsOfService
                      ? formik.touched.termsOfService &&
                        formik.errors.termsOfService
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ margin: theme.spacing(3, 2, 3) }}
              >
                <InfoIcon color="primary" />
                <Typography component="h5" variant="body2">
                  Password must be at least 8 characters long and have one
                  uppercase, lowercase, number, and special character
                </Typography>
              </Stack>
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              // className={theme.submit}
              loading={loading}
              loadingPosition="start"
              startIcon={<i className="fas fa-spinner fa-spin"></i>}
              sx={{
                margin: theme.spacing(3, 0, 2),
                // textTransform: "none",
                // backgroundColor: "#D0021B",
                backgroundColor: "#000",
                "&:hover": {
                  backgroundColor: "#635BFF",
                },
              }}
              disabled={Object.keys(formik.errors).length > 0}
              size="large"
            >
              Start Free Trial
            </LoadingButton>
            <Grid container>
              <Grid item>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ margin: theme.spacing(0) }}
                >
                  Already have an account?&nbsp;
                </Typography>
                <Link href="/login" variant="body2">
                  Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </StyledContainerDiv>
      </Container>
    </ThemeProvider>
  );
};

export default SignUpForm;
