import { useState, useEffect, useCallback } from "react";

const useSubscriptionStatus = (apiService) => {
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(null); // null: not checked, true: subscribed, false: not subscribed
  const [subscribedPlanId, setSubscribedPlanId] = useState(null);

  const checkSubscriptionStatus = useCallback(async () => {
    setLoading(true);
    if (!apiService.hasSessionToken()) {
      setSubscribedPlanId(null);
      setIsSubscribed(false);
      setLoading(false);
      return;
    }

    const response = await apiService.getData("/subscription");
    if (response && response.data && response.data.is_subscribed) {
      setSubscribedPlanId(response.data.account_type);
      setIsSubscribed(true);
      setLoading(false);
    } else {
      setSubscribedPlanId(null);
      setIsSubscribed(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    checkSubscriptionStatus();
  }, [checkSubscriptionStatus]);

  return { isSubscribed, subscribedPlanId, loading };
};

export default useSubscriptionStatus;
