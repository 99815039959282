import React from "react";
import { CssBaseline, ThemeProvider, Alert, Box } from "@mui/material";
import useCustomTheme from "../hooks/useCustomTheme";
import { ReactComponent as LoadingIcon } from "../images/loading-icon.svg";

const LoadingComponent = (props) => {
  const { loading } = props;
  const theme = useCustomTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="loading-container" sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <LoadingIcon className="loading-icon" style={{
          width: "64px",
        }}/>
      </Box>
    </ThemeProvider>
  );
};

export default LoadingComponent;
