/* global gtag */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Grid,
  TextField,
  Typography,
  Link,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SyncIcon from "@mui/icons-material/Sync";
import BlockIcon from "@mui/icons-material/Block";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router";
import useCustomTheme from "../../hooks/useCustomTheme";
import Layout from "../../components/layout";
import LoadingComponent from "../../components/loading";

const timezonesObject = [
  {
    id: 548,
    shortName: "EDT",
    offset: -240,
    name: "-04:00 GMT (EDT) US/Canada/Eastern, Colombia, Peru",
  },
  {
    id: 546,
    shortName: "CDT",
    offset: -300,
    name: "-05:00 GMT (CDT) US/Canada/Central",
  },
  {
    id: 117,
    shortName: "CST",
    offset: -360,
    name: "-06:00 GMT (CST) America/Guatemala",
  },
  {
    id: 552,
    shortName: "MDT",
    offset: -360,
    name: "-06:00 GMT (MDT) US/Canada/Mountain",
  },
  {
    id: 545,
    shortName: "MST",
    offset: -420,
    name: "-07:00 GMT (MST) US/Arizona",
  },
  {
    id: 553,
    shortName: "PDT",
    dstShortName: "PST",
    offset: -420,
    name: "-07:00 GMT (PDT) US/Canada/Pacific",
    zone: "America/Los_Angeles",
  },
  {
    id: 543,
    shortName: "AKDT",
    offset: -480,
    name: "-08:00 GMT (AKDT) Alaska/Pitcairn Islands",
  },
  {
    id: 549,
    shortName: "HST",
    offset: -600,
    name: "-10:00 GMT (HST) Hawaiian",
  },
  {
    id: 422,
    shortName: "BST",
    offset: 60,
    name: "+01:00 GMT (BST) Britain, Ireland, Portugal, Western Africa",
  },
  { id: 2, shortName: "GMT", offset: 0, name: "+00:00 GMT (GMT) West Africa" },
  {
    id: 432,
    shortName: "CEST",
    offset: 120,
    name: "+02:00 GMT (CEST) Western Europe, Central Africa",
  },
  {
    id: 4,
    shortName: "CET",
    offset: 60,
    name: "+01:00 GMT (CET) West/Central Africa",
  },
  {
    id: 414,
    shortName: "EEST",
    offset: 180,
    name: "+03:00 GMT (EEST) Eastern Europe, Eastern Africa",
  },
  {
    id: 14,
    shortName: "EET",
    offset: 120,
    name: "+02:00 GMT (EET) South/Central Africa",
  },
  {
    id: 429,
    shortName: "MSK",
    offset: 180,
    name: "+03:00 GMT (MSK) Russia, Saudi Arabia",
  },
  {
    id: 20,
    shortName: "EAT",
    offset: 180,
    name: "+03:00 GMT (EAT) East Africa",
  },
  { id: 271, shortName: "GST", offset: 240, name: "+04:00 GMT (GST) Arabian" },
  {
    id: 476,
    shortName: "MUT",
    offset: 240,
    name: "+04:00 GMT (MUT) Mauritius, Reunion and Seychelles",
  },
  {
    id: 257,
    shortName: "PKT",
    offset: 300,
    name: "+05:00 GMT (PKT) West Asia, Pakistan",
  },
  { id: 261, shortName: "IST", offset: 330, name: "+05:30 GMT (IST) India" },
  {
    id: 239,
    shortName: "BDT",
    offset: 360,
    name: "+06:00 GMT (BDT) Central Asia",
  },
  {
    id: 230,
    shortName: "ICT",
    offset: 420,
    name: "+07:00 GMT (ICT) Bangkok, Hanoi, Jakarta",
  },
  {
    id: 540,
    shortName: "SGT",
    offset: 480,
    name: "+08:00 GMT (SGT) China, Singapore, Taiwan",
  },
  {
    id: 482,
    shortName: "JST",
    offset: 540,
    name: "+09:00 GMT (JST) Korea, Japan",
  },
  {
    id: 332,
    shortName: "ACST",
    offset: 570,
    name: "+09:30 GMT (ACST) Central Australia",
  },
  {
    id: 320,
    shortName: "AEST",
    offset: 600,
    name: "+10:00 GMT (AEST) Eastern Australia",
  },
  {
    id: 507,
    shortName: "SBT",
    offset: 660,
    name: "+11:00 GMT (SBT) Central Pacific",
  },
  {
    id: 503,
    shortName: "FJT",
    offset: 720,
    name: "+12:00 GMT (FJT) Fiji Islands Standard Time",
  },
  {
    id: 497,
    shortName: "NZST",
    offset: 720,
    name: "+12:00 GMT (NZST) New Zealand Standard Time",
  },
  {
    id: 501,
    shortName: "PHOT",
    offset: 780,
    name: "+13:00 GMT (PHOT) Dateline",
  },
  { id: 528, shortName: "SST", offset: -660, name: "-11:00 GMT (SST) Samoa" },
  {
    id: 347,
    shortName: "ADT",
    offset: -180,
    name: "-03:00 GMT (ADT) Atlantic Standard Time",
  },
  {
    id: 344,
    shortName: "AMT",
    offset: -240,
    name: "-04:00 GMT (AMT) Western Brazil",
  },
  {
    id: 352,
    shortName: "NDT",
    offset: -150,
    name: "-02:30 GMT (NDT) Newfoundland",
  },
  {
    id: 59,
    shortName: "ART",
    offset: -180,
    name: "-03:00 GMT (ART) Argentina, Eastern Brazil, Greenland",
  },
  {
    id: 164,
    shortName: "FNT",
    offset: -120,
    name: "-02:00 GMT (FNT) Mid-Atlantic",
  },
  {
    id: 306,
    shortName: "AZOST",
    offset: 0,
    name: "+00:00 GMT (AZOST) Azores/Eastern Atlantic",
  },
  {
    id: 309,
    shortName: "CVT",
    offset: -60,
    name: "-01:00 GMT (CVT) Cape Verde Time Zone",
  },
  { id: 556, shortName: "UTC", offset: 0, name: "+00:00 GMT (UTC) UTC" },
];

const validationSchema = yup.object({
  nickName: yup
    .string()
    .min(2, "URL Name should be of minimum 3 characters length")
    .required("URL Name is required"),
  businessName: yup.string().required("Business Name is required"),
  timezoneId: yup.number().required("Timezone is required"),
});

const Intro = () => {
  const [timezoneOffset, setTimezoneOffset] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [pageReady, setPageReady] = React.useState(false);
  const [checkingNickName, setCheckingNickName] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const loc = useLocation();
  const [sessionToken, setSessionToken] = React.useState(
    loc.state && loc.state.sessionToken ? loc.state.sessionToken : undefined,
  );
  const [nickNameAvailable, setNickNameAvailable] = React.useState(false);
  const navigate = useNavigate();
  const theme = useCustomTheme();
  const [coupon, setCoupon] = React.useState(undefined);

  const getAccountInfo = async () => {
    try {
      const accountInfoResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/account?session_token=${sessionToken}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (accountInfoResponse.ok) {
        const accountInfoResponseJson = await accountInfoResponse.json();

        if (
          accountInfoResponseJson.data &&
          accountInfoResponseJson.data.discount_code
        ) {
          setCoupon(accountInfoResponseJson.data.discount_code);
        }

        if (
          accountInfoResponseJson.data.company_name !== "" &&
          accountInfoResponseJson.data.company_name !== null &&
          accountInfoResponseJson.data.company_name !== undefined
        ) {
          navigate("/intro/start", {
            state: {
              nickName: accountInfoResponseJson.data.nick_name,
              sessionToken: sessionToken,
              skipped: true,
              subscribed: true,
            },
          });

          formik.setFieldValue(
            "nickName",
            accountInfoResponseJson.data.nick_name,
          );
          formik.setFieldValue(
            "businessName",
            accountInfoResponseJson.data.company_name,
          );
          formik.setFieldValue(
            "timezoneId",
            accountInfoResponseJson.data.timezone_id,
          );
        }
      }
      setPageReady(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (sessionToken === undefined) {
      navigate("/login", { state: { redirectTo: "/intro" } });
    } else {
      getAccountInfo();
    }
  }, [sessionToken]);

  const timezoneChange = (event, el) => {
    formik.setFieldValue("timezoneId", Number(event.target.value));
    setTimezoneOffset(Number(el.props["data-timezone-offset"]));
  };

  const checkNickName = async (nickName) => {
    if (!formik.errors.nickName && formik.values.nickName !== "") {
      try {
        const signupResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/account/checknickname/${nickName}?session_token=${sessionToken}&nick_name=${nickName}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (signupResponse.ok) {
          const signupResponseJson = await signupResponse.json();
          setNickNameAvailable(true);
          setCheckingNickName(false);
        } else {
          const signupResponseJson = await signupResponse.json();
          setCheckingNickName(false);
          setNickNameAvailable(false);
          formik.setErrors({
            nickName: "Nickname already exists",
          });
        }
      } catch (error) {
        console.log(error);
        formik.setErrors({
          nickName: "Could not check nickname",
        });
        setNickNameAvailable(false);
        setCheckingNickName(false);
      }
    } else {
      setNickNameAvailable(false);
      setCheckingNickName(false);
    }
  };

  const nickNameIcon = () => {
    if (checkingNickName) {
      return <SyncIcon color="primary" />;
    } else if (formik.values.nickName === "") {
      return (
        <RadioButtonUncheckedIcon
          color={formik.errors.nickName ? "error" : "primary"}
        />
      );
    } else if (formik.errors.nickName || !nickNameAvailable) {
      return <BlockIcon color="error" />;
    } else {
      return <CheckCircleIcon color="success" />;
    }
  };

  const formik = useFormik({
    initialValues: {
      nickName: "",
      businessName: "",
      timezoneId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setErrors([]);
      try {
        const saveBusinessInfoResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/account/saveBussinesInfo?session_token=${sessionToken}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              nick_name: values.nickName,
              company_name: values.businessName,
              timezone_id: values.timezoneId,
            }),
          },
        );

        if (saveBusinessInfoResponse.ok) {
          // Ensure gtag is defined
          // @ts-ignore
          if (typeof gtag !== "undefined") {
            // @ts-ignore
            gtag("event", "signup_setup", {
              event_category: "signup",
              event_label: "Signup Setup",
            });
          }
          const saveBusinessInfoResponseJson =
            await saveBusinessInfoResponse.json();
          navigate("/intro/plans", {
            state: {
              sessionToken: sessionToken,
              nickName: values.nickName,
              coupon: coupon,
            },
          });
        } else {
          const saveBusinessInfoResponseJson =
            await saveBusinessInfoResponse.json();
          const error = {
            type: "error",
            message: saveBusinessInfoResponseJson.statusDescription,
          };
          setErrors([error]);
        }
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  useEffect(() => {
    setCheckingNickName(true);
    const delay = 1000; // Adjust the delay (in milliseconds) as needed

    const timeoutId = setTimeout(() => {
      checkNickName(formik.values.nickName);
    }, delay);

    // Clear the timeout if the component re-renders before the delay is reached
    return () => clearTimeout(timeoutId);
  }, [formik.values.nickName]);

  return (
    <Layout
      errors={errors}
      itemsSx={{
        backgroundColor: "rgba(255,255,255)",
        width: "800px",
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, 1),
        },
      }}
      contentGridSx={{
        padding: {
          xs: theme.spacing(2),
          sm: theme.spacing(5),
          md: theme.spacing(5),
          lg: theme.spacing(5),
        },
      }}
    >
      <Helmet>
        <title>Welcome to Setster - Begin Your Scheduling Journey</title>
        <meta
          name="description"
          content="Please answer a few questions to tailor the system to your scheduling needs."
        />
        <meta name="author" content="Setster" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta
          property="og:title"
          content="Welcome to Setster - Begin Your Scheduling Journey"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.setster.com" />
        <meta property="og:site_name" content="Setster" />
        <meta
          property="og:description"
          content="Please answer a few questions to tailor the system to your scheduling needs."
        />
        <meta property="og:author" content="Setster" />
        <meta
          property="og:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta property="og:image:width" content="280" />
        <meta property="og:image:height" content="90" />
        <meta
          name="twitter:title"
          content="Welcome to Setster - Begin Your Scheduling Journey"
        />
        <meta
          name="twitter:description"
          content="Please answer a few questions to tailor the system to your scheduling needs."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Setster" />
        <meta name="twitter:url" content="https://www.setster.com" />
        <meta
          name="twitter:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta name="twitter:image:width" content="280" />
        <meta name="twitter:image:height" content="90" />
        <link rel="alternate" href="https://www.setster.com/intro" />
        <link rel="canonical" href="https://www.setster.com/intro" />
      </Helmet>

      {!pageReady ? (
        <LoadingComponent />
      ) : (
        <>
          <Typography
            component="h1"
            variant="h5"
            sx={{ margin: theme.spacing(0, 0, 3) }}
            textAlign="left"
          >
            Welcome, let's get you set up.
          </Typography>
          <Typography
            component="h1"
            variant="body1"
            textAlign="left"
            sx={{ margin: theme.spacing(0, 0, 3) }}
          >
            Create your Setster booking URL
          </Typography>
          <form
            style={{
              width: "100%",
              marginTop: "50px",
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  height: {
                    xs: "90px",
                    sm: "70px",
                    md: "70px",
                    lg: "70px",
                    xl: "70px",
                    xxl: "70px",
                    xxxl: "70px",
                  },
                }}
              >
                <TextField
                  size="small"
                  autoComplete="nickname"
                  name="nickName"
                  variant="outlined"
                  required
                  fullWidth
                  id="nickName"
                  label="URL Name"
                  autoFocus
                  value={formik.values.nickName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    (formik.touched.nickName &&
                      Boolean(formik.errors.nickName)) ||
                    !nickNameAvailable
                  }
                  helperText={formik.errors.nickName}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        .setster.com
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        {nickNameIcon()}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  height: {
                    xs: "90px",
                    sm: "70px",
                    md: "70px",
                    lg: "70px",
                    xl: "70px",
                    xxl: "70px",
                    xxxl: "70px",
                  },
                }}
              >
                <TextField
                  size="small"
                  autoComplete="businessName"
                  name="businessName"
                  variant="outlined"
                  required
                  fullWidth
                  id="businessName"
                  label="Business Name"
                  value={formik.values.businessName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.businessName &&
                    Boolean(formik.errors.businessName)
                  }
                  helperText={
                    formik.touched.businessName && formik.errors.businessName
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  height: {
                    xs: "90px",
                    sm: "70px",
                    md: "70px",
                    lg: "70px",
                    xl: "70px",
                    xxl: "70px",
                    xxxl: "70px",
                  },
                }}
              >
                <TextField
                  size="small"
                  autoComplete="timezoneId"
                  name="timezoneId"
                  variant="outlined"
                  required
                  fullWidth
                  id="timezoneId"
                  label="Timezone"
                  value={formik.values.timezoneId}
                  onChange={timezoneChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.timezoneId &&
                    Boolean(formik.errors.timezoneId)
                  }
                  helperText={
                    formik.touched.timezoneId && formik.errors.timezoneId
                  }
                  select={true}
                >
                  {timezonesObject.map((timezone) => {
                    return (
                      <MenuItem
                        key={`timezone-${timezone.id}`}
                        value={timezone.id}
                        data-zone-name={timezone.shortName}
                        data-timezone-offset={timezone.offset}
                      >
                        <Typography style={{ whiteSpace: "normal" }}>
                          {timezone.name}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
              loadingPosition="start"
              startIcon={<i className="fas fa-spinner fa-spin"></i>}
              sx={{
                margin: theme.spacing(3, 0, 2),
                backgroundColor: "#000",
                "&:hover": {
                  backgroundColor: "#635BFF",
                },
              }}
              disabled={
                Object.keys(formik.errors).length > 0 || !nickNameAvailable
              }
              size="large"
            >
              Continue
            </LoadingButton>
          </form>

          <Typography
            component="span"
            variant="body2"
            textAlign="left"
            sx={{ margin: theme.spacing(0, 0, 3) }}
          >
            {"Need Help? "}
            <Link href="https://support.setster.com/hc/en-us" variant="body2">
              Visit support
            </Link>
            {" or "}
            <Link
              href="https://support.setster.com/hc/en-us/requests/new"
              variant="body2"
            >
              contact us
            </Link>
            {"."}
          </Typography>
        </>
      )}
    </Layout>
  );
};

export default Intro;
