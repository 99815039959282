// src/components/SetsterLogo.js
import React from "react";
import { useTheme } from "@mui/material/styles"; // Import useTheme if you are using it for spacing
import useMediaQuery from "@mui/material/useMediaQuery";

const SetsterLogo = ({ src, alt = "Setster Logo" }) => {
  const theme = useTheme();

  // Responsive logic using useMediaQuery
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));

  const padding = isMd ? "0px" : "10px 0 0 0";
  const paddingBottom = isXs || isSm || isMd ? "15px" : "15px";

  return (
    <img
      src={src}
      alt={alt}
      style={{
        width: "100px",
        margin: theme.spacing(0, 0, 1),
        padding: padding,
        paddingBottom: paddingBottom,
      }}
    />
  );
};

export default SetsterLogo;
