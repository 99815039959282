// src/hooks/useTheme.js
import { useMemo } from "react";
import { createTheme } from "@mui/material/styles";

const useCustomTheme = () => {
  const theme = useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1500,
          },
        },
        palette: {
          primary: {
            main: "#635BFF",
          },
        },
        typography: {
          button: {
            textTransform: "none",
          },
        },
      }),
    []
  );

  return theme;
};

export default useCustomTheme;
