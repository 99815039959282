/* global gtag */
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, TextField, Typography, Link, Input } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useFormik } from "formik";
import * as yup from "yup";
import useCustomTheme from "../../hooks/useCustomTheme";
import Layout from "../../components/layout";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email",
    )
    .required("Email is required"),
});

const VerifyEmail = () => {
  const [recaptchaToken, setRecaptchaToken] = React.useState(undefined);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [email, setEmail] = React.useState(
    location.state && location.state.email ? location.state.email : undefined,
  );
  const [emailSent, setEmailSent] = React.useState(false);
  const theme = useCustomTheme();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setErrors([]);
      setLoading(true);
      const resp = await handleResendEmail(values.email);
      if (resp.ok) {
        // Ensure gtag is defined
        // @ts-ignore
        if (typeof gtag !== "undefined") {
          // @ts-ignore
          gtag("event", "signup_verify_resend", {
            event_category: "signup",
            event_label: "Resend Email Verification",
          });
        }
        setEmail(values.email);
        setEmailSent(true);
        const success = {
          type: "success",
          message: "Email sent successfully",
        };
        setErrors([success]);
      } else {
        const respJson = await resp.json();
        const error = {
          type: "error",
          message: respJson.statusDescription,
        };
        setErrors([error]);
        setEmailSent(false);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (email !== undefined) {
      formik.setFieldValue("email", email);
    }
  }, [email]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("signup_page");
    if (token) {
      setRecaptchaToken(token);
      return true;
    }

    return false;
  }, [executeRecaptcha]);

  const handleResendEmail = async (emailAddress) => {
    await handleReCaptchaVerify();

    const parsedData = {
      email: emailAddress,
      recaptcha_token: recaptchaToken,
    };

    try {
      const resendReponse = await fetch(
        `${process.env.REACT_APP_API_URL}/account/resendVerificationEmail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(parsedData),
        },
      );

      return resendReponse;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <Layout
      errors={errors}
      itemsSx={{
        backgroundColor: "rgba(255,255,255)",
      }}
      contentGridSx={{
        margin: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(2),
        },
      }}
    >
      <Helmet>
        <title>Verify Your Email</title>
        <meta
          name="description"
          content="Check your inbox to verify your email and complete the Setster signup process."
        />
        <meta name="author" content="Setster" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta name="robots" content="noindex" />
        <meta property="og:title" content="Verify Your Email" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.setster.com" />
        <meta property="og:site_name" content="Setster" />
        <meta
          property="og:description"
          content="Check your inbox to verify your email and complete the Setster signup process."
        />
        <meta property="og:author" content="Setster" />
        <meta
          property="og:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta property="og:image:width" content="280" />
        <meta property="og:image:height" content="90" />
        <meta name="twitter:title" content="Verify Your Email" />
        <meta
          name="twitter:description"
          content="Check your inbox to verify your email and complete the Setster signup process."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Setster" />
        <meta name="twitter:url" content="https://www.setster.com" />
        <meta
          name="twitter:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta name="twitter:image:width" content="280" />
        <meta name="twitter:image:height" content="90" />
        <link rel="alternate" href="https://www.setster.com/verify-email" />
        <link rel="canonical" href="https://www.setster.com/verify-email" />
      </Helmet>

      {/*First, let's verify your email */}
      <Typography
        component="h1"
        variant="h5"
        sx={{ margin: theme.spacing(0, 0, 3) }}
        textAlign="left"
      >
        Welcome. Let's verify your email.
      </Typography>

      <form
        style={{
          width: "100%",
        }}
        onSubmit={formik.handleSubmit}
      >
        {email !== undefined ? (
          <>
            <Typography
              component="h1"
              variant="body1"
              // sx={{ margin: theme.spacing(1) }}
              textAlign="left"
            >
              A verification email was sent to:
            </Typography>
            <Typography
              component="h1"
              variant="body1"
              sx={{ fontWeight: "bolder" }}
              textAlign="left"
            >
              {email}
            </Typography>
            <Input type="hidden" name="email" id="email" value={email} />
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="email"
                  name="email"
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  autoFocus
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>
          </>
        )}

        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          loading={loading}
          loadingPosition="start"
          startIcon={<i className="fas fa-spinner fa-spin"></i>}
          sx={{
            margin: theme.spacing(3, 0, 2),
            backgroundColor: "#000",
            "&:hover": {
              backgroundColor: "#635BFF",
            },
          }}
          disabled={Object.keys(formik.errors).length > 0 || emailSent}
          size="large"
        >
          Resend Email
        </LoadingButton>
      </form>
      <Typography
        component="h1"
        variant="body2"
        sx={{ margin: theme.spacing(1) }}
        textAlign="left"
      >
        Need Help?&nbsp;
        <Link href="https://support.setster.com/hc/en-us" variant="body2">
          Visit support
        </Link>
        &nbsp;or&nbsp;
        <Link
          href="https://support.setster.com/hc/en-us/requests/new"
          variant="body2"
        >
          contact us
        </Link>
        .
      </Typography>
    </Layout>
  );
};

export default VerifyEmail;
