import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Grid,
  Typography,
  TextField,
  Stack,
  Link
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import useCustomTheme from "../../hooks/useCustomTheme";
import Layout from "../../components/layout";

const ForgotPassword = () => {
  const [loading, setLoading] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);
  const [emailAddress, setEmailAddress] = React.useState("");
  const [errors, setErrors] = React.useState([]);
  const [recaptchaToken, setRecaptchaToken] = React.useState(undefined);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const theme = useCustomTheme();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      )
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      
      const resp = await handleResendEmail(values.email);
      if (resp.ok) {
        setEmailSent(true);
        setLoading(false);
      } else {
        const respJson = await resp.json();
        const error = {
          type: "error",
          message: respJson.statusDescription,
        };
        setErrors([error]);
        setLoading(false);
      }
    },
  });

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("forgot_password_page");
    if (token) {
      setRecaptchaToken(token);
      return true;
    }

    return false;
  }, [executeRecaptcha]);

  const handleResendEmail = async (emailAddress) => {
    setErrors([]);
    await handleReCaptchaVerify();
    setEmailAddress(emailAddress);

    const parsedData = {
      email: emailAddress,
      recaptcha_token: recaptchaToken,
    };

    try {
      const resendReponse = await fetch(
        `${process.env.REACT_APP_API_URL}/account/ResendPasswordResetEmail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(parsedData),
        }
      );

      if (resendReponse.ok && emailSent === true) {
        const success = {
          type: "success",
          message: "Email sent successfully",
        };
        setErrors([success]);
      }

      return resendReponse;
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <Layout
      errors={errors}
      itemsSx={{
        width: { xs: "100%", sm: "600px", md: "600px", lg: "600px" },
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, 1),
        },
      }}
      contentGridSx={{
        [theme.breakpoints.up("sm")]: {
          margin: theme.spacing(0, 4),
        },
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, 2),
        },
      }}
    >
      <Helmet>
        <title>Setster Password Recovery | Reset Your Setster Password</title>
        <meta
          name="description"
          content="Forgot your Setster password? No worries! Quickly initiate a password reset to regain access."
        />
        <meta name="author" content="Setster" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta
          property="og:title"
          content="Setster Password Recovery | Reset Your Setster Password"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.setster.com" />
        <meta property="og:site_name" content="Setster" />
        <meta
          property="og:description"
          content="Forgot your Setster password? No worries! Quickly initiate a password reset to regain access."
        />
        <meta property="og:author" content="Setster" />
        <meta
          property="og:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta property="og:image:width" content="280" />
        <meta property="og:image:height" content="90" />
        <meta
          name="twitter:title"
          content="Setster Password Recovery | Reset Your Setster Password"
        />
        <meta
          name="twitter:description"
          content="Forgot your Setster password? No worries! Quickly initiate a password reset to regain access."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Setster" />
        <meta name="twitter:url" content="https://www.setster.com" />
        <meta
          name="twitter:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta name="twitter:image:width" content="280" />
        <meta name="twitter:image:height" content="90" />
        <link rel="alternate" href="https://www.setster.com/forgot-password" />
        <link rel="canonical" href="https://www.setster.com/forgot-password" />
      </Helmet>

      <form onSubmit={formik.handleSubmit}>
        <>
          {emailSent !== true ? (
            <>
              <Typography
                component="h1"
                variant="h5"
                sx={{ margin: theme.spacing(0, 0) }}
                textAlign="left"
              >
                Reset Your Password
              </Typography>
              <Typography
                component="h2"
                variant="body1"
                sx={{ margin: theme.spacing(0, 0, 3) }}
                textAlign="left"
              >
                Enter your email address, and we'll send you a password reset
                link.
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    height: {
                      xs: "90px",
                      sm: "70px",
                      md: "70px",
                      lg: "70px",
                      xl: "70px",
                      xxl: "70px",
                      xxxl: "70px",
                    },
                  }}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    id="email"
                    required
                    fullWidth={true}
                    name="email"
                    label="Email"
                    autoComplete="email"
                    autoFocus={true}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
              </Grid>
              <Stack
                spacing={2}
                direction="row"
                sx={{ margin: theme.spacing(1, 0, 3) }}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<i className="fas fa-spinner fa-spin"></i>}
                  disableElevation={true}
                  disabled={formik.errors.email || emailSent ? true : false}
                  size="large"
                  fullWidth={true}
                  sx={{
                    margin: theme.spacing(3, 0, 2),
                    backgroundColor: "#000",
                    "&:hover": {
                      backgroundColor: "#635BFF",
                    },
                  }}
                >
                  Continue
                </LoadingButton>
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Link href="/login" color="primary" variant="body2">
                  Return to sign in
                </Link>
              </Stack>
              <Stack
                spacing={0}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ margin: theme.spacing(1, 0) }}
              >
                <Typography variant="body2" sx={{ margin: theme.spacing(0) }}>
                  Don't have an account?&nbsp;
                </Typography>
                <Link href="/signup" color="primary" variant="body2">
                  Sign up
                </Link>
              </Stack>
            </>
          ) : (
            <>
              <Typography
                component="h1"
                variant="h5"
                sx={{ margin: theme.spacing(0, 0, 5, 0) }}
                textAlign="left"
              >
                If {emailAddress} is associated with an account, then we've sent
                you an email with instructions to reset your password.
              </Typography>
              <Typography
                component="h2"
                variant="body1"
                sx={{ margin: theme.spacing(0, 0, 5, 0) }}
                textAlign="left"
              >
                If you haven't received an email in a few minutes, please check
                your spam folder,{" "}
                <Link href="#" onClick={() => handleResendEmail(emailAddress)}>
                  resend
                </Link>{" "}
                the email or{" "}
                <Link
                  href="#"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  try a different email address
                </Link>
                .
              </Typography>
            </>
          )}
        </>
      </form>
    </Layout>
  );
};

export default ForgotPassword;
