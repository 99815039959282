import React from "react";
import {
  CssBaseline,
  ThemeProvider,
  Alert
} from "@mui/material";
import useCustomTheme from "../hooks/useCustomTheme";

const ErrorComponent = (props) => {
  const { errors } = props;
  const theme = useCustomTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {errors.map((error, index) => (
        <Alert key={`error-${index}`} severity={error.type}>{error.message}</Alert>
      ))}
    </ThemeProvider>
  );
};

export default ErrorComponent;
