/* global gtag */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Alert,
  AlertTitle,
  Grid,
  Typography,
  TextField,
  Box,
  TableHead,
  Switch,
  Button,
} from "@mui/material";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { LoadingButton } from "@mui/lab";
import ApiService from "../../services/apiService";
import useSubscriptionStatus from "../../hooks/useSubscriptionStatus";
import useCustomTheme from "../../hooks/useCustomTheme";
import Layout from "../../components/layout";
import LoadingComponent from "../../components/loading";
import DiscountIcon from "@mui/icons-material/Discount";

const validationSchema = yup.object({
  seats: yup
    .number()
    .required("Please enter the number of seats you need.")
    .positive("Please enter a positive number.")
    .integer("Please enter a whole number."),
  fullName: yup.string().required("Please enter your full name."),
  cardNumber: yup
    .string()
    .required("Please enter your card number.")
    .matches(/^[0-9]{16}$/, "Please enter a valid 16-digit card number."),
  expirationDate: yup
    .mixed()
    .required("Please enter your card expiration date.")
    .test(
      "validDate",
      "Expiration date must be in MM/YY format",
      function (value) {
        return moment.isMoment(value) && value.isValid();
      },
    )
    .test(
      "expirationDate",
      "Expiration date cannot be in the past",
      function (value) {
        if (!moment.isMoment(value)) return false;

        const now = moment();
        return value.endOf("month").isAfter(now);
      },
    ),
  cvv: yup
    .string()
    .required("Please enter your card CVV.")
    .matches(/^[0-9]{3}$/, "Please enter a valid 3-digit CVV number."),
  billingZip: yup
    .string()
    .required("Please enter your billing zip code.")
    .matches(
      /^\d{5}(-\d{4})?$|^[A-Z]\d[A-Z] ?\d[A-Z]\d$|^\d{4,6}$|^\d{3}-\d{4}$|^\d{2}-\d{3}-\d{2}$/,
      "Please enter a valid 5-digit zip code.",
    ),
  coupon: yup.string().nullable(),
});

const IntroPayment = () => {
  const [checkingCoupon, setCheckingCoupon] = React.useState(false);
  const [couponValid, setCouponValid] = React.useState(undefined);
  const [couponInfo, setCouponInfo] = React.useState(undefined);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pageReady, setPageReady] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const loc = useLocation();
  const [sessionToken, setSessionToken] = React.useState(
    loc.state && loc.state.sessionToken ? loc.state.sessionToken : undefined,
  );
  const [nickName, setNickName] = React.useState(
    loc.state && loc.state.nickName ? loc.state.nickName : undefined,
  );
  const [accountInfo, setAccountInfo] = React.useState(
    loc.state && loc.state.accountInfo ? loc.state.accountInfo : undefined,
  );
  const plan = loc.state && loc.state.plan ? loc.state.plan : undefined;
  const [monthly, setMonthly] = React.useState(true);
  const [planName, setPlanName] = React.useState("");
  const [planPrice, setPlanPrice] = React.useState(0);
  const [seatPrice, setSeatPrice] = React.useState(0);
  const [seatTotalPrice, setSeatTotalPrice] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const coupon = loc.state && loc.state.coupon ? loc.state.coupon : undefined;

  const navigate = useNavigate();
  const theme = useCustomTheme();
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

  const logout = () => {
    setSessionToken(undefined);
    setAccountInfo(undefined);
    setNickName(undefined);
    navigate("/login", { state: { redirectTo: "/intro/payment" } });
  };
  const apiService = new ApiService(sessionToken, logout);

  const setPrices = () => {
    let planPrice = 0;
    let seatPrice = 0;
    let seatTotalPrice = 0;
    let total = 0;
    const seats =
      formik.values.seats && formik.values.seats > 0 ? formik.values.seats : 0;

    let seatPricePerMonth = null;
    //TODO: Right now all plans have the seats additional service set with id 3. If this changes, it will break things. Fix this.
    const additionalSeatInfo = plan.additionalServices[3];
    if (additionalSeatInfo) {
      seatPricePerMonth = Number(additionalSeatInfo.price);
    }

    if (monthly) {
      planPrice = Number(plan.prices.yearly);
      seatPrice = seatPricePerMonth;
      seatTotalPrice = seatPrice * seats;
      total = planPrice + seatTotalPrice;
    } else {
      const pricePerMonth = Number(plan.prices.yearly);
      planPrice =
        (pricePerMonth - (pricePerMonth * plan.prices.yearly_discount) / 100) *
        12;
      seatPrice =
        (seatPricePerMonth -
          (seatPricePerMonth * plan.prices.yearly_discount) / 100) *
        12;
      seatTotalPrice = seatPrice * seats;
      total = planPrice + seatTotalPrice;
    }

    setPlanPrice(planPrice);
    setSeatPrice(seatPrice);
    setSeatTotalPrice(seatTotalPrice);
    setTotal(total);
  };

  const choosePlan = () => {
    navigate("/intro/plans", { state: { sessionToken: sessionToken } });
  };

  const formik = useFormik({
    initialValues: {
      seats: 1,
      fullName: "",
      cardNumber: "",
      expirationDate: null,
      cvv: "",
      billingZip: "",
      coupon: coupon,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setErrors([]);

      //TODO: Right now all plans have the seats additional service set with id 3. If this changes, it will break things. Fix this.
      const additionalServices = [];
      const additionalServicesInfo = plan.additionalServices[3];
      if (additionalServicesInfo) {
        additionalServices.push({
          id: 3,
          chargify_id: monthly
            ? additionalServicesInfo.chargify_monthly_id
            : additionalServicesInfo.chargify_yearly_id,
          qty: Number(formik.values.seats),
        });
      }

      const ccMonth = values.expirationDate.format("MM");
      const ccYear = values.expirationDate.format("YY");
      const paymentData = {
        ccName: values.fullName,
        type: plan.id,
        period: monthly ? 1 : 12,
        ccNo: values.cardNumber,
        ccMonth: Number(ccMonth),
        ccYear: Number(ccYear),
        ccCVV2: Number(values.cvv),
        ccZip: values.billingZip,
        additional_services: additionalServices,
        isanual: monthly ? 0 : 1,
        coupon: values.coupon,
      };
      if (!couponValid) {
        delete paymentData.coupon;
      }
      try {
        const paymentResponse = await apiService.registerPayment(
          accountInfo.company_id,
          paymentData,
        );
        if (paymentResponse.data !== undefined) {
          // Ensure gtag is defined
          // @ts-ignore
          if (typeof gtag !== "undefined") {
            // @ts-ignore
            gtag("event", "signup_paid", {
              event_category: "signup",
              event_label: "Signup Paid",
              plan: plan.name,
              seats: formik.values.seats,
              annual: monthly ? 0 : 1,
            });
          }

          navigate("/intro/start", {
            state: {
              nickName: nickName,
              sessionToken: sessionToken,
              plan: plan,
              skipped: false,
            },
          });
        }
      } catch (error) {
        if (error.response.data.data.errors) {
          const errors = [];
          error.response.data.data.errors.forEach((error) => {
            errors.push({
              message: error,
              type: "error",
            });
          });
          setErrors(errors);
        } else {
          setErrors([
            {
              message: "An error occurred while processing your payment.",
              type: "error",
            },
          ]);
        }
      }

      setLoading(false);
    },
  });

  useEffect(() => {
    if (sessionToken === undefined) {
      navigate("/login", { state: { redirectTo: "/intro/payment" } });
    }
  }, [sessionToken]);

  useEffect(() => {
    if (plan === undefined) {
      navigate("/intro/plans", { state: { sessionToken: sessionToken } });
    }
  }, [plan]);

  useEffect(() => {
    if (plan !== undefined) {
      setPlanName(plan.name);
      setPrices();
    }
  }, [monthly, plan, formik.values.seats]);

  useEffect(() => {
    setCouponValid(undefined);
    setCouponInfo(undefined);
    delete formik.errors.coupon;
  }, [monthly]);

  useEffect(() => {
    const validate = async () => {
      const formErrors = await formik.validateForm();
      if (Object.keys(formErrors).length === 0) {
        setCanSubmit(true);
      } else {
        setCanSubmit(false);
      }
    };
    validate();
  }, [formik.values]);

  useEffect(() => {
    if (accountInfo === undefined) {
      const getAccountInfo = async () => {
        try {
          const response = await apiService.getAccountInfo();
          if (response.data !== undefined) {
            setAccountInfo(response.data);
          }
        } catch (error) {
          setErrors([
            {
              message: "An error occurred while fetching account information.",
              type: "error",
            },
          ]);
        }
      };
      getAccountInfo();
      setPageReady(true);
    }
  }, [accountInfo]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { isSubscribed, subscribedPlanId, subscriptionCheckLoading } =
    useSubscriptionStatus(apiService);

  useEffect(() => {
    if (isSubscribed === true) {
      navigate("/intro/start", {
        state: {
          nickName: nickName,
          sessionToken: sessionToken,
          skipped: false,
          subscribed: isSubscribed,
          plan: plan,
        },
      });
    }
  }, [isSubscribed]);

  const checkCoupon = async (coupon) => {
    coupon = coupon.toUpperCase();
    if (!formik.errors.coupon && formik.values.coupon !== "") {
      try {
        const couponResponse = await apiService.checkCoupon(
          plan.id,
          coupon,
          monthly,
        );

        if (couponResponse.data !== undefined) {
          if (couponResponse.data && couponResponse.data.code === coupon) {
            setCouponValid(true);
            setCouponInfo(couponResponse.data);
            formik.values.coupon = coupon;
          } else {
            formik.setErrors({
              coupon: "Invalid coupon code",
            });
            setCouponValid(false);
            setCouponInfo(undefined);
          }
          setCheckingCoupon(false);
        } else {
          formik.setErrors({
            coupon: "Invalid coupon code",
          });
          setCouponValid(false);
          setCheckingCoupon(false);
          setCouponInfo(undefined);
        }
      } catch (error) {
        //If the response is 404 it means the coupon is invalid
        if (error.response.status === 404) {
          formik.setErrors({
            coupon: "Invalid coupon code",
          });
        } else {
          formik.setErrors({
            coupon: "An error occurred while checking the coupon.",
          });
        }
        setCouponValid(false);
        setCheckingCoupon(false);
        setCouponInfo(undefined);
      }
    } else {
      setCheckingCoupon(false);
      setCouponValid(true);
      setCouponInfo(undefined);
    }
  };

  //TODO: Change SEO details with the correct ones
  return (
    <Layout
      errors={errors}
      itemsSx={{
        backgroundColor: "rgba(255,255,255)",
        width: "800px",
        paddingTop: "10px !important",
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, 1),
        },
      }}
      contentGridSx={{
        overflowY: windowHeight * 0.8 < 1000 ? "auto" : "visible",
        maxHeight: "90vh",
        padding: {
          xs: theme.spacing(2),
          sm: theme.spacing(5),
          md: theme.spacing(5),
          lg: theme.spacing(5),
        },
        [theme.breakpoints.down("sm")]: {
          height: "80vh",
          overflowY: "auto",
        },
      }}
    >
      <Helmet>
        <title>Setster - Custom Booking Solutions for Every Need</title>
        <meta
          name="description"
          content="Setster provides custom booking solutions tailored for small businesses, large businesses, and developers. Choose the right option for your needs and integrate an efficient booking system seamlessly."
        />
        <meta
          name="keywords"
          content="booking solutions, small business booking, large business booking, developer booking solution, custom booking integration, Setster"
        />
        <meta name="author" content="Setster" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta
          property="og:title"
          content="Setster - Custom Booking Solutions for Every Need"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.setster.com" />
        <meta property="og:site_name" content="Setster" />
        <meta
          property="og:description"
          content="Setster provides custom booking solutions tailored for small businesses, large businesses, and developers. Choose the right option for your needs and integrate an efficient booking system seamlessly."
        />
        <meta property="og:author" content="Setster" />
        <meta
          property="og:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta property="og:image:width" content="280" />
        <meta property="og:image:height" content="90" />
        <meta
          name="twitter:title"
          content="Setster - Custom Booking Solutions for Every Need"
        />
        <meta
          name="twitter:description"
          content="Setster provides custom booking solutions tailored for small businesses, large businesses, and developers. Choose the right option for your needs and integrate an efficient booking system seamlessly."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Setster" />
        <meta name="twitter:url" content="https://www.setster.com" />
        <meta
          name="twitter:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta name="twitter:image:width" content="280" />
        <meta name="twitter:image:height" content="90" />
        <link rel="alternate" href="https://www.setster.com/intro/use" />
        <link rel="canonical" href="https://www.setster.com/intro/use" />
      </Helmet>

      {!pageReady || subscriptionCheckLoading ? (
        <LoadingComponent />
      ) : (
        <Box
          sx={{
            width: "100%",
            margin: theme.spacing(0),
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box mt={2}>
              <Grid item xs={12} sm={12}>
                <Typography
                  component="h2"
                  variant="h6"
                  sx={{ margin: theme.spacing(0, 0, 1, 0) }}
                  textAlign="left"
                >
                  Seats Needed
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography
                      variant="body1"
                      sx={{ margin: theme.spacing(0, 0) }}
                      textAlign="left"
                    >
                      How many seats do you need?
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ margin: theme.spacing(0, 0, 3) }}
                      textAlign="left"
                    >
                      You will get{" "}
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: 600,
                        }}
                      >
                        {formik.values.seats && formik.values.seats > 0
                          ? formik.values.seats
                          : 0}
                      </Typography>{" "}
                      locations and{" "}
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: 600,
                        }}
                      >
                        {formik.values.seats && formik.values.seats > 0
                          ? formik.values.seats
                          : 0}
                      </Typography>{" "}
                      staff members.
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      tabIndex={1}
                      type="number"
                      size="small"
                      autoComplete="seats"
                      name="seats"
                      variant="outlined"
                      required
                      id="seats"
                      label="Seats"
                      autoFocus
                      value={formik.values.seats}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{
                        min: 1,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      error={
                        formik.touched.seats && Boolean(formik.errors.seats)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  component="h2"
                  variant="h6"
                  sx={{ margin: theme.spacing(0, 0, 2, 0) }}
                  textAlign="left"
                >
                  Billing Summary
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      component="span"
                      variant="body1"
                      color={monthly ? "primary" : ""}
                    >
                      Monthly
                    </Typography>
                    <Switch
                      tabIndex={2}
                      checked={!monthly}
                      onChange={() => setMonthly(!monthly)}
                    />
                    <Typography
                      component="span"
                      variant="body1"
                      color={!monthly ? "primary" : ""}
                    >
                      Annual 20% Discount
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TableContainer>
                      <Table style={{ tableLayout: "fixed" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{planName} Plan</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">1</TableCell>
                            <TableCell align="right">
                              ${planPrice.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Seats</TableCell>
                            <TableCell align="right">
                              ${seatPrice.toFixed(2)}/seat
                            </TableCell>
                            <TableCell align="right">
                              {formik.values.seats}
                            </TableCell>
                            <TableCell align="right">
                              ${seatTotalPrice.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{ borderBottom: "none", fontWeight: 600 }}
                            >
                              Total
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{ borderBottom: "none" }}
                            ></TableCell>
                            <TableCell
                              align="right"
                              sx={{ borderBottom: "none" }}
                            ></TableCell>
                            <TableCell
                              align="right"
                              sx={{ borderBottom: "none", fontWeight: 600 }}
                            >
                              ${total.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  component="h2"
                  variant="h6"
                  sx={{ margin: theme.spacing(2, 0, 2, 0) }}
                  textAlign="left"
                >
                  Discount Code
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={10} sm={10} sx={{ margin: theme.spacing(0) }}>
                    <TextField
                      tabIndex={7}
                      type="text"
                      size="small"
                      autoComplete="coupon"
                      name="coupon"
                      variant="outlined"
                      fullWidth
                      id="coupon"
                      label="Coupon code"
                      value={formik.values.coupon}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        (formik.touched.coupon &&
                          Boolean(formik.errors.coupon)) ||
                        couponValid === false
                      }
                      helperText={formik.errors.coupon}
                      InputLabelProps={{
                        shrink: true, // This ensures the label is always shown
                      }}
                      placeholder="Enter your discount code"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      tabIndex={8}
                      type="button"
                      variant="contained"
                      color="primary"
                      sx={{
                        margin: theme.spacing(0),
                      }}
                      size="large"
                      onClick={() => checkCoupon(formik.values.coupon)}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
                {couponInfo && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      margin: theme.spacing(2, 0),
                    }}
                  >
                    <Alert
                      variant="filled"
                      icon={<DiscountIcon fontSize="inherit" />}
                      color="primary"
                    >
                      <AlertTitle>Code: {couponInfo.code}</AlertTitle>
                      {couponInfo.description}
                    </Alert>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  component="h2"
                  variant="h6"
                  sx={{ margin: theme.spacing(2, 0, 2, 0) }}
                  textAlign="left"
                >
                  Payment
                </Typography>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      height: {
                        xs: "90px",
                        sm: "70px",
                        md: "70px",
                        lg: "70px",
                        xl: "70px",
                        xxl: "70px",
                        xxxl: "70px",
                      },
                    }}
                  >
                    <TextField
                      tabIndex={3}
                      type="text"
                      size="small"
                      autoComplete="fullName"
                      name="fullName"
                      variant="outlined"
                      fullWidth
                      required
                      id="full-name"
                      label="Full name"
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{
                        shrink: true, // This ensures the label is always shown
                      }}
                      placeholder="John Doe"
                      error={
                        formik.touched.fullName &&
                        Boolean(formik.errors.fullName)
                      }
                      helperText={
                        formik.touched.fullName && formik.errors.fullName
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      height: {
                        xs: "90px",
                        sm: "70px",
                        md: "70px",
                        lg: "70px",
                        xl: "70px",
                        xxl: "70px",
                        xxxl: "70px",
                      },
                    }}
                  >
                    <TextField
                      tabIndex={3}
                      type="text"
                      size="small"
                      autoComplete="cardNumber"
                      name="cardNumber"
                      variant="outlined"
                      fullWidth
                      required
                      id="card-number"
                      label="Card number"
                      value={formik.values.cardNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{
                        shrink: true, // This ensures the label is always shown
                      }}
                      placeholder="4242********4242"
                      error={
                        formik.touched.cardNumber &&
                        Boolean(formik.errors.cardNumber)
                      }
                      helperText={
                        formik.touched.cardNumber && formik.errors.cardNumber
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      height: {
                        xs: "120px",
                        sm: "80px",
                        md: "80px",
                        lg: "80px",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateField
                            tabIndex={4}
                            label="Expiration date"
                            size="small"
                            format="MM/YY"
                            disablePast
                            name="expirationDate"
                            variant="outlined"
                            fullWidth
                            required
                            id="expiration-date"
                            // @ts-ignore
                            helperText={
                              formik.touched.expirationDate &&
                              formik.errors.expirationDate
                            }
                            InputLabelProps={{ shrink: true }}
                            value={formik.values.expirationDate}
                            onChange={(newValue) =>
                              formik.setFieldValue("expirationDate", newValue)
                            }
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.expirationDate &&
                              Boolean(formik.errors.expirationDate)
                            }
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          tabIndex={5}
                          type="text"
                          size="small"
                          autoComplete="cvv"
                          name="cvv"
                          variant="outlined"
                          fullWidth
                          required
                          id="cvv"
                          label="CVV"
                          value={formik.values.cvv}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          InputLabelProps={{
                            shrink: true, // This ensures the label is always shown
                          }}
                          placeholder="123"
                          error={
                            formik.touched.cvv && Boolean(formik.errors.cvv)
                          }
                          helperText={formik.touched.cvv && formik.errors.cvv}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          tabIndex={6}
                          type="text"
                          size="small"
                          autoComplete="billingZip"
                          name="billingZip"
                          variant="outlined"
                          fullWidth
                          required
                          id="billing-zip"
                          label="Billing zip"
                          value={formik.values.billingZip}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          InputLabelProps={{
                            shrink: true, // This ensures the label is always shown
                          }}
                          placeholder="12345"
                          error={
                            formik.touched.billingZip &&
                            Boolean(formik.errors.billingZip)
                          }
                          helperText={
                            formik.touched.billingZip &&
                            formik.errors.billingZip
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      type="button"
                      variant="text"
                      color="primary"
                      sx={{
                        margin: theme.spacing(0),
                        color: theme.palette.grey[500],
                        textAlign: "left",
                      }}
                      size="large"
                      onClick={choosePlan}
                    >
                      <NavigateBeforeIcon fontSize="small" />
                      Choose a Plan{" "}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!canSubmit}
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<i className="fas fa-spinner fa-spin"></i>}
                      sx={{
                        margin: theme.spacing(0),
                      }}
                      size="large"
                    >
                      Confirm
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      )}
    </Layout>
  );
};

export default IntroPayment;
