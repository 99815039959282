import React from "react";
import { Helmet } from "react-helmet";
import { createRoot } from "react-dom/client";
import SignInSide from "./pages/signup/SignInSide";
import VerifyEmail from "./pages/verify-email/VerifyEmail";
import Intro from "./pages/intro/Intro";
import IntroUse from "./pages/intro/IntroUse";
import IntroStart from "./pages/intro/IntroStart";
import IntroPlans from "./pages/intro/IntroPlans";
import EmailConfirmed from "./pages/email-confirmed/EmailConfirmed";
import Login from "./pages/login/Login";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "./styles.css";
import IntroPayment from "./pages/intro/IntroPayment";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/signup" element={<SignInSide />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/email-confirmed" element={<EmailConfirmed />} />
      <Route path="/intro/use" element={<IntroUse />} />
      <Route path="/intro" element={<Intro />} />
      <Route path="/intro/plans" element={<IntroPlans />} />
      <Route path="/intro/payment" element={<IntroPayment />} />
      <Route path="/intro/start" element={<IntroStart />} />
    </>
  )
);
export default function App() {
  return (
    <React.StrictMode>
      <Helmet titleTemplate="%s | Setster" defaultTitle="Setster">
      </Helmet>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

createRoot(document.getElementById("root")).render(
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
    scriptProps={{
      async: true, // optional, default to false,
      defer: true, // optional, default to false
      appendTo: "head", // optional, default to "head", can be "head" or "body",
      nonce: undefined, // optional, default undefined
    }}
    // container={{ // optional to render inside custom element
    //   element: "[required_id_or_htmlelement]",
    //   parameters: {
    //     badge: '[inline|bottomright|bottomleft]', // optional, default undefined
    //     theme: 'dark', // optional, default undefined
    //   }
    // }}
  >
    <App />
  </GoogleReCaptchaProvider>
);
