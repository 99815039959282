import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.breakpoints.values.sm !== undefined ? "10px" : "60px",
  [theme.breakpoints.up("sm")]: {
    padding: "10px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "60px",
  },
  paddingTop: theme.breakpoints.values.sm !== undefined ? "10px" : "30px",
  [theme.breakpoints.up("sm")]: {
    paddingTop: "20px",
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: "30px",
  },
  [theme.breakpoints.up("lg")]: {
    paddingTop: "50px",
  },
}));

export default Item;
