/* global gtag */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Typography, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import ApiService from "../../services/apiService";
import useSubscriptionStatus from "../../hooks/useSubscriptionStatus";
import useCustomTheme from "../../hooks/useCustomTheme";
import Layout from "../../components/layout";
import LoadingComponent from "../../components/loading";

const plans = {
  7: {
    features: {
      list: [
        "Unlimited Services & Appointments",
        "Booking Integration: Customizable widget, embeddable button, hosted booking page.",
        "Custom Notifications: Multiple email and SMS alerts for both clients and staff.",
        "Payments: Accept credit cards via Stripe or PayPal.",
        "Calendar Syncing: Sync with Google or Microsoft Calendar.",
        "Virtual Meetings: Conduct meetings with Zoom or Microsoft Teams.",
        "Integration: Connect with Google Analytics, Salesforce, FreshBooks, and 500+ apps via Zapier.",
        "Client & Staff Management: Manage team access and roles and log client booking history.",
        "Data and Reporting: Analytics, powerful reporting, data exports.",
        "Support: Access to help center and appointed account manager.",
      ],
    },
  },
  8: {
    features: {
      title: "All Growth Features Plus",
      list: [
        "API Access: Full non-rate limited scheduling API and webhooks.",
        "Advanced Scheduling: Complex availability, booking restrictions, custom workflows, and automation.",
        "Scalability & Security: Unlimited scaling, seasonal influx reliability, and secure backups.",
        "Technical Support: Enhanced integration guidance, data migration, and developer support.",
      ],
    },
  },
  9: {
    features: {
      title: "All Developer Features Plus",
      list: [
        "Dedicated Environment: Dedicated hosting, regional flexibility, enhanced security, and performance optimization.",
        "Custom SLA: Real-time monitoring, guaranteed uptime, and fault tolerance.",
        "Custom Source Code: Bespoke booking rules, language modification, and tailored workflow logic.",
      ],
    },
  },
};

const validationSchema = yup.object({
  solutionType: yup.number().oneOf([7, 8]),
});

const IntroPlans = () => {
  const [loading, setLoading] = React.useState(false);
  const [pageReady, setPageReady] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const loc = useLocation();
  const sessionToken =
    loc.state && loc.state.sessionToken ? loc.state.sessionToken : undefined;
  const nickName =
    loc.state && loc.state.nickName ? loc.state.nickName : undefined;
  const [accountInfo, setAccountInfo] = React.useState(
    loc.state && loc.state.accountInfo ? loc.state.accountInfo : undefined,
  );
  const [selectedPlan, setSelectedPlan] = React.useState(undefined);
  const [canSkip, setCanSkip] = React.useState(true);
  const [dbPlans, setDbPlans] = React.useState(plans);
  const [coupon, setCoupon] = React.useState(loc.state?.coupon);

  const navigate = useNavigate();
  const theme = useCustomTheme();
  const apiService = new ApiService(sessionToken);

  const { isSubscribed, subscribedPlanId, subscriptionCheckLoading } =
    useSubscriptionStatus(apiService);

  useEffect(() => {
    if (sessionToken === undefined) {
      navigate("/login", { state: { redirectTo: "/intro/plans" } });
    }
  }, [sessionToken]);

  useEffect(() => {
    if (isSubscribed === true) {
      navigate("/intro/start", {
        state: {
          nickName: nickName,
          sessionToken: sessionToken,
          skipped: false,
          plan: plans[subscribedPlanId],
        },
      });
    } else if (isSubscribed === false) {
      setPageReady(true);
    } else if (isSubscribed === null) {
      setPageReady(false);
    }
  }, [isSubscribed]);

  useEffect(() => {
    if (accountInfo === undefined) {
      const getAccountInfo = async () => {
        try {
          if (apiService.hasSessionToken()) {
            const response = await apiService.getAccountInfo();
            if (response.data !== undefined) {
              setAccountInfo(response.data);
              setCoupon(response.data.discount_code);
            }
          }
        } catch (error) {
          console.log(error);
          setErrors([
            {
              message: "An error occurred while fetching account information.",
              type: "error",
            },
          ]);
        }
      };
      getAccountInfo();
    }

    if (
      accountInfo &&
      (accountInfo.status == "1" || accountInfo.status == "2")
    ) {
      setCanSkip(false);
    }
  }, [accountInfo]);

  useEffect(() => {
    const getPlans = async () => {
      try {
        const plansResponse = await apiService.getPlans();
        if (plansResponse.data && plansResponse.data.length > 0) {
          const updatedPlans = { ...plans };
          plansResponse.data.forEach((plan) => {
            if (plan.deprecated !== true) {
              updatedPlans[plan.id] = {
                ...plan,
                features: plans[plan.id]?.features || {},
              };
              setDbPlans(updatedPlans);
            }
          });
        }
      } catch (error) {
        setErrors([
          { message: "An error occurred while fetching plans.", type: "error" },
        ]);
      }
    };
    getPlans();
  }, []);

  const Plan = (props) => {
    const { plan } = props;
    const [open, setOpen] = React.useState(false);
    if (plan.prices !== undefined) {
      let seatPrice = null;

      //TODO: Right now all plans have the seats additional service set with id 3. If this changes, it will break things. Fix this.
      const additionalSeatInfo = plan.additionalServices[3];
      if (additionalSeatInfo) {
        seatPrice = Number(additionalSeatInfo.price);
      }

      const selectPlan = (planId) => {
        if (selectedPlan === planId) {
          setSelectedPlan(undefined);
          formik.setFieldValue("selectedPlan", undefined);
          return;
        }

        setSelectedPlan(planId);
        formik.setFieldValue("selectedPlan", planId);
      };

      const talkToSales = () => {
        // Ensure gtag is defined
        // @ts-ignore
        if (typeof gtag !== "undefined") {
          // @ts-ignore
          gtag("event", "signup_plan_select", {
            event_category: "signup",
            event_label: "Signup Choose Plan",
            type: "dedicated",
          });
        }

        const talkToSalesUrl = `https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/sales-appointment`;
        window.open(talkToSalesUrl, "_blank");
      };

      return (
        <>
          <TableRow
            sx={{ "& > *": { borderBottom: "unset" } }}
            selected={selectedPlan === plan.id}
          >
            <TableCell
              align="left"
              size="small"
              style={{ width: "32px", padding: "6px 0" }}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "bold" }}>
              {plan.name}
            </TableCell>
            <TableCell align="right" padding="none">
              {plan.id === 9
                ? "Starting at $12k / year"
                : `$${
                    plan.prices.yearly -
                    (plan.prices.yearly * plan.prices.yearly_discount) / 100
                  } / month`}
              {plan.id !== 9 && seatPrice !== null && (
                <>
                  <br />
                  Plus $
                  {seatPrice -
                    (seatPrice * plan.prices.yearly_discount) / 100}{" "}
                  / seat
                </>
              )}
            </TableCell>
            <TableCell
              align="center"
              size="small"
              style={{
                width: "auto",
                [theme.breakpoints.down["sm"]]: {
                  textAlign: "center",
                },
              }}
            >
              {plan.id === 9 ? (
                <Button
                  variant="text"
                  color="primary"
                  onClick={talkToSales}
                  sx={{
                    margin: theme.spacing(0),
                  }}
                  size="large"
                >
                  Inquire
                </Button>
              ) : (
                <Checkbox
                  color="primary"
                  checked={selectedPlan === plan.id}
                  onChange={() => selectPlan(plan.id)}
                  inputProps={{
                    "aria-label": plan.name,
                  }}
                />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1, marginTop: 2 }}>
                  <Typography
                    variant="subtitle2"
                    component="h3"
                    fontWeight="bold"
                  >
                    {plan.features.title}
                  </Typography>
                  <ul style={{ paddingInlineStart: 0 }}>
                    {plan.features.list.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      );
    }

    return null;
  };

  const skipStep = () => {
    // Ensure gtag is defined
    // @ts-ignore
    if (typeof gtag !== "undefined") {
      // @ts-ignore
      gtag("event", "signup_plan_skip", {
        event_category: "signup",
        event_label: "Signup Choose Plan",
      });
    }
    navigate("/intro/start", {
      state: { nickName: nickName, sessionToken: sessionToken, skipped: true },
    });
  };

  const formik = useFormik({
    initialValues: {
      selectedPlan: undefined,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setErrors([]);

      try {
        if (values.selectedPlan !== undefined) {
          const plan = dbPlans[values.selectedPlan];
          if (plan === undefined) {
            setErrors([
              {
                message: "An error occurred while selecting the plan.",
                type: "error",
              },
            ]);
            return;
          }

          // Ensure gtag is defined
          // @ts-ignore
          if (typeof gtag !== "undefined") {
            // @ts-ignore
            gtag("event", "signup_plan_select", {
              event_category: "signup",
              event_label: "Signup Choose Plan",
              type: plan.key,
            });
          }
          navigate("/intro/payment", {
            state: {
              nickName: nickName,
              sessionToken: sessionToken,
              plan: plan,
              coupon: coupon,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  //TODO: Change SEO details with the correct ones
  return (
    <Layout
      errors={errors}
      itemsSx={{
        backgroundColor: "rgba(255,255,255)",
        width: "800px",
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, 1),
        },
      }}
      contentGridSx={{
        padding: {
          xs: theme.spacing(2),
          sm: theme.spacing(5),
          md: theme.spacing(5),
          lg: theme.spacing(5),
        },
        [theme.breakpoints.down("sm")]: {
          maxHeight: "80vh",
          overflowY: "auto",
        },
      }}
    >
      <Helmet>
        <title>Setster - Custom Booking Solutions for Every Need</title>
        <meta
          name="description"
          content="Setster provides custom booking solutions tailored for small businesses, large businesses, and developers. Choose the right option for your needs and integrate an efficient booking system seamlessly."
        />
        <meta
          name="keywords"
          content="booking solutions, small business booking, large business booking, developer booking solution, custom booking integration, Setster"
        />
        <meta name="author" content="Setster" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta
          property="og:title"
          content="Setster - Custom Booking Solutions for Every Need"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.setster.com" />
        <meta property="og:site_name" content="Setster" />
        <meta
          property="og:description"
          content="Setster provides custom booking solutions tailored for small businesses, large businesses, and developers. Choose the right option for your needs and integrate an efficient booking system seamlessly."
        />
        <meta property="og:author" content="Setster" />
        <meta
          property="og:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta property="og:image:width" content="280" />
        <meta property="og:image:height" content="90" />
        <meta
          name="twitter:title"
          content="Setster - Custom Booking Solutions for Every Need"
        />
        <meta
          name="twitter:description"
          content="Setster provides custom booking solutions tailored for small businesses, large businesses, and developers. Choose the right option for your needs and integrate an efficient booking system seamlessly."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Setster" />
        <meta name="twitter:url" content="https://www.setster.com" />
        <meta
          name="twitter:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta name="twitter:image:width" content="280" />
        <meta name="twitter:image:height" content="90" />
        <link rel="alternate" href="https://www.setster.com/intro/use" />
        <link rel="canonical" href="https://www.setster.com/intro/use" />
      </Helmet>
      {!pageReady || subscriptionCheckLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <Typography
            component="h1"
            variant="h5"
            sx={{ margin: theme.spacing(0, 0, 3) }}
            textAlign="left"
          >
            Let's find the best plan for your needs.
          </Typography>
          <form
            style={{
              width: "100%",
              marginTop: "50px",
              // margin: theme.spacing(3),
            }}
            onSubmit={formik.handleSubmit}
          >
            <TableContainer component={Box}>
              <Table aria-label="collapsible table">
                <TableBody>
                  {Object.keys(dbPlans).map((planId) => {
                    const plan = dbPlans[planId];
                    return <Plan key={planId} plan={plan} />;
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid item xs={12} sx={{ marginTop: "40px" }}>
              {(selectedPlan !== undefined || canSkip === false) && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={loading}
                    loadingPosition="start"
                    disabled={selectedPlan === undefined}
                    startIcon={<i className="fas fa-spinner fa-spin"></i>}
                    sx={{
                      margin: theme.spacing(0),
                    }}
                    size="large"
                  >
                    Choose Number Of Seats <NavigateNextIcon fontSize="small" />
                  </LoadingButton>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {canSkip && (
                  <Button
                    type="button"
                    variant="text"
                    color="primary"
                    sx={{
                      margin: theme.spacing(0),
                      color:
                        selectedPlan === undefined
                          ? theme.palette.primary.main
                          : theme.palette.grey[500],
                    }}
                    size="large"
                    onClick={skipStep}
                  >
                    Skip and Start Trial
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Layout>
  );
};

export default IntroPlans;
