import axios from "axios";

class ApiService {
  constructor(sessionToken, logoutCallback) {
    this.sessionToken = sessionToken;
    this.logoutCallback = logoutCallback;
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Interceptor to add session token as a query parameter
    this.api.interceptors.request.use(
      (config) => {
        if (sessionToken) {
          config.params = config.params || {};
          config.params["session_token"] = sessionToken;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  async getData(endpoint, params = {}) {
    try {
      const response = await this.api.get(endpoint, { params });
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async postData(endpoint, data) {
    try {
      const response = await this.api.post(endpoint, data);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async putData(endpoint, data) {
    try {
      const response = await this.api.put(endpoint, data);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async deleteData(endpoint) {
    try {
      const response = await this.api.delete(endpoint);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getAccountInfo() {
    return this.getData("/account");
  }

  async getBusiness() {
    return this.getData("/account/GetAccountInfo");
  }

  async getLocations(companyId) {
    return this.getData(`/location`, { company_id: companyId });
  }

  async getServices(companyId) {
    return this.getData(`/service`, { company_id: companyId });
  }

  async getStaff(companyId) {
    return this.getData(`/staff`, { company_id: companyId });
  }

  async getPlans() {
    return this.getData("/subscription/plans");
  }

  async getAvailability(locationId, serviceId, startDate, timezoneId) {
    return this.getData("/availability", {
      location_id: locationId,
      service_id: serviceId,
      start_date: startDate,
      timezone_id: timezoneId,
      return: "times",
      first_available: true,
    });
  }

  async createAppointment(appointment) {
    return this.postData("/appointment", appointment);
  }

  async registerPayment(companyId, payment) {
    return this.postData(
      `/account/registerPayment?company_id=${companyId}`,
      payment,
    );
  }

  async checkCoupon(planId, coupon, monthly) {
    const data = {
      plan: planId,
      coupon,
      monthly,
    };

    return this.postData(`/subscription/checkCoupon`, data);
  }

  hasSessionToken() {
    return this.sessionToken ? true : false;
  }

  handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        if (this.logoutCallback) {
          this.logoutCallback();
        }

        console.log("You are not logged in.");
        throw new Error("You are not logged in.");
      }
    }

    console.error("API call failed. ", error);
    throw error;
  }
}

export default ApiService;
