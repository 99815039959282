/* global gtag */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  CardActions,
  Button,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useLocation } from "react-router";
import moment from "moment";
import ApiService from "../../services/apiService";
import useCustomTheme from "../../hooks/useCustomTheme";
import Layout from "../../components/layout";

const IntroStart = () => {
  const [errors, setErrors] = React.useState([]);
  const loc = useLocation();
  const [loading, setLoading] = React.useState(false);
  const sessionToken =
    loc.state && loc.state.sessionToken ? loc.state.sessionToken : undefined;
  const [nickName, setNickName] = React.useState(
    loc.state && loc.state.nickName ? loc.state.nickName : undefined
  );
  const skipped = loc.state && loc.state.skipped !== undefined ? loc.state.skipped : true;
  const subscribed = loc.state && loc.state.subscribed !== undefined ? loc.state.subscribed : false;
  const plan = loc.state && loc.state.plan ? loc.state.plan : undefined;
  const navigate = useNavigate();
  const theme = useCustomTheme();

  const apiService = new ApiService(sessionToken);

  const getAccountInfo = async () => {
    try {
      const accountInfoResponse = await apiService.getAccountInfo();

      if (accountInfoResponse.data !== undefined) {
        const accountInfo = accountInfoResponse.data;
        if (accountInfo.status == '1' || accountInfo.status == '2') {
          navigate("/intro/plans", { state: { sessionToken: sessionToken, accountInfo: accountInfo } });
        }
        const nickName = accountInfo.nick_name;
        setNickName(nickName);
      }
    } catch (error) {
      console.log(error);
      setErrors([
        {
          message: error.message,
          type: "error",
        },
      ]);
    }
  };

  const walktrough = async (event) => {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    try {
      const businessResponse = await apiService.getBusiness();
      const business = businessResponse.data;
      if (business && business.company_id !== undefined) {
        const locationsResponse = await apiService.getLocations(
          business.company_id
        );
        if (locationsResponse.data !== undefined) {
          const location = locationsResponse.data[0];
          const serviceResponse = await apiService.getServices(business.company_id);
          if (serviceResponse.data !== undefined) {
            const service = serviceResponse.data[0];
            const availabilityResponse = await apiService.getAvailability(
              location.id,
              service.id,
              moment().format("YYYY-MM-DD"),
              business.timezone_id
            );
            if (availabilityResponse.data !== undefined) {
              const availability = availabilityResponse.data;
              if (availability && availability.times !== undefined) {
                const date = Object.keys(availability.times)[0];
                const time = availability.times[date][0];
                const appointmentStartDateTime = moment(
                  `${date} ${time}`,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm");

                const appointment = {
                  client_email: "test@setster.com",
                  client_name: "Test Customer",
                  employee_id: 0,
                  location_id: Number(location.id),
                  service_id: Number(service.id),
                  start_date: appointmentStartDateTime,
                  note: "Test appointment",
                };

                try {
                  const appointmentResponse = await apiService.createAppointment(appointment);
                  if (appointmentResponse.data !== undefined) {
                    // Ensure gtag is defined
                    // @ts-ignore
                    if (typeof gtag !== "undefined") {
                      // @ts-ignore
                      gtag("event", "signup_intro_start", {
                        event_category: "signup",
                        event_label: "Signup Intro Start",
                        next: "walkthrough",
                      });
                    }
                    const cookieInfo = {
                      appDate: moment(appointment.start_date).format(
                        "YYYY-MM-DD"
                      ),
                      firstLogin: true,
                    };

                    let expires = "";
                    const date = new Date();
                    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
                    expires = "; expires=" + date.toUTCString();
                    document.cookie = `firstLogin=${JSON.stringify(
                      cookieInfo
                    )}${expires}; path=/`;

                    const url = `https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/admin`;
                    window.open(url);
                  } else {
                    console.error(appointmentResponse);
                    setErrors([
                      {
                        message: "Could not create test appointment",
                        type: "error",
                      },
                    ]);
                  }
                } catch (error) {
                  console.error(error);
                  setErrors([
                    {
                      message: "Something went wrong",
                      type: "error",
                    },
                  ]);
                }
              } else {
                setErrors([
                  { message: "No availability set for this Account", type: "error" },
                ]);
              }
            } else {
              setErrors([
                { message: "Could not fetch availability", type: "error" },
              ]);
            }
            
          } else {
            setErrors([
              {
                message: "Could not fetch service info",
                type: "error",
              },
            ]);
          }
        } else {
          setErrors([
            {
              message: "Could not fetch location info",
              type: "error",
            },
          ]);
        }
      } else {
        setErrors([
          {
            message: "Could not fetch business info",
            type: "error",
          },
        ]);
      }
    } catch (error) {
      console.log(error);
      setErrors([
        {
          message: error.message,
          type: "error",
        },
      ]); 
    }

    setLoading(false);
  };

  const goToPlans = (event) => {
    if (event) {
      event.preventDefault();
    }

    navigate("/intro/plans", { state: { sessionToken: sessionToken } });
  };

  useEffect(() => {
    if (sessionToken === undefined) {
      navigate("/login", { state: { redirectTo: "/intro/start" } });
    } else {
      getAccountInfo();
    }
  }, [sessionToken]);

  return (
    <Layout
      errors={errors}
      itemsSx={{
        backgroundColor: "rgba(255,255,255)",
        width: { xs: "100%", sm: "650px", md: "650px", lg: "650px" },
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, 1),
        },
      }}
      contentGridSx={{
        padding: {
          xs: theme.spacing(2),
          sm: theme.spacing(5),
          md: theme.spacing(5),
          lg: theme.spacing(5),
        },
        [theme.breakpoints.down("sm")]: {
          height: "80vh",
          overflow: "scroll",
        },
      }}
    >
      <Helmet>
        <title>Your Setster Account is Ready - Let's Get Started</title>
        <meta
          name="description"
          content="Begin exploring the features of Setster and dive into the documentation to get familiar with Setster's booking engine."
        />
        <meta name="author" content="Setster" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta
          property="og:title"
          content="Your Setster Account is Ready - Let's Get Started"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.setster.com" />
        <meta property="og:site_name" content="Setster" />
        <meta
          property="og:description"
          content="Begin exploring the features of Setster and dive into the documentation to get familiar with Setster's booking engine."
        />
        <meta property="og:author" content="Setster" />
        <meta
          property="og:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta property="og:image:width" content="280" />
        <meta property="og:image:height" content="90" />
        <meta
          name="twitter:title"
          content="Your Setster Account is Ready - Let's Get Started"
        />
        <meta
          name="twitter:description"
          content="Begin exploring the features of Setster and dive into the documentation to get familiar with Setster's booking engine."
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Setster" />
        <meta name="twitter:url" content="https://www.setster.com" />
        <meta
          name="twitter:image"
          content="https://www.setster.com/landing-page/images/setster-logo.png"
        />
        <meta name="twitter:image:width" content="280" />
        <meta name="twitter:image:height" content="90" />
        <link rel="alternate" href="https://www.setster.com/intro/start" />
        <link rel="canonical" href="https://www.setster.com/intro/start" />
      </Helmet>

      <Grid container>
        {skipped === true ? (
          <>
            <Grid item xs={6}>
              <Typography
                component="h1"
                variant="h5"
                sx={{ margin: theme.spacing(0, 0, 3, 0) }}
                textAlign="left"
              >
                Let's dive in.
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              {!subscribed && (
                <Link
                  variant="body2"
                  color="primary"
                  href={`https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/sales-appointment`}
                  target="_blank"
                  onClick={() => {
                    // Ensure gtag is defined
                    // @ts-ignore
                    if (typeof gtag !== "undefined") {
                      // @ts-ignore
                      gtag("event", "signup_intro_start", {
                        event_category: "signup",
                        event_label: "Signup Intro Start",
                        next: "schedule_call",
                      });
                    }
                  }}
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: theme.spacing(0),
                  }}
                >
                  Schedule Onboarding Call <NavigateNextIcon fontSize="small" />
                </Link>
              )}
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography component="p" variant="body1">
              Welcome to your new {plan.name} Plan.
            </Typography>
            <Typography
              component="h1"
              variant="h5"
              sx={{ margin: theme.spacing(0, 0, 3, 0) }}
              textAlign="left"
            >
              Let's dive in to all our features.
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Card
            elevation={0}
            variant="outlined"
            sx={{ margin: theme.spacing(0, 0, 3, 0) }}
          >
            <CardHeader
              title="Account Setup"
              titleTypographyProps={{ variant: "h6" }}
              sx={{
                background: theme.palette.grey[200],
                padding: theme.spacing(1, 2),
              }}
            />
            <CardActions>
              <Grid container justifyContent="space-between" direction="column">
                <Typography variant="body2">
                  <LoadingButton
                    variant="text"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<i className="fas fa-spinner fa-spin"></i>}
                    href={`https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/admin`}
                    onClick={walktrough}
                    sx={{
                      textAlign: "left",
                      marginLeft: "-4px",
                      transition: "all 0.3s ease",
                      paddingLeft: theme.spacing(loading ? 4 : 0),
                      textDecoration: "underline",
                      "&:hover": {
                        textDecoration: "underline",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    Show me around
                  </LoadingButton>
                  Walkthrough the backend.
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: "10px" }}>
                  <Link
                    variant="body2"
                    color="primary"
                    href={
                      nickName !== undefined
                        ? `https://${nickName}.${process.env.REACT_APP_SETSTER_DOMAIN}`
                        : "#"
                    }
                    target="_blank"
                    onClick={() => {
                      // Ensure gtag is defined
                      // @ts-ignore
                      if (typeof gtag !== "undefined") {
                        // @ts-ignore
                        gtag("event", "signup_intro_start", {
                          event_category: "signup",
                          event_label: "Signup Intro Start",
                          next: "booking_page",
                        });
                      }
                    }}
                    sx={{
                      padding: theme.spacing(0, 1, 0, 0),
                    }}
                  >
                    View my booking page
                  </Link>
                  Book a test appointment.
                </Typography>
                <Typography variant="body2">
                  <Link
                    variant="body2"
                    color="primary"
                    href={`https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/studio`}
                    target="_blank"
                    onClick={() => {
                      // Ensure gtag is defined
                      // @ts-ignore
                      if (typeof gtag !== "undefined") {
                        // @ts-ignore
                        gtag("event", "signup_intro_start", {
                          event_category: "signup",
                          event_label: "Signup Intro Start",
                          next: "about_studio",
                        });
                      }
                    }}
                    sx={{
                      padding: theme.spacing(0, 1, 0, 0),
                    }}
                  >
                    Learn about Studio
                  </Link>
                  Build beautiful booking experiences.
                </Typography>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            elevation={0}
            variant="outlined"
            sx={{ margin: theme.spacing(0, 0, 3, 0) }}
          >
            <CardHeader
              title="Developer Docs"
              titleTypographyProps={{ variant: "h6", color: "white" }}
              sx={{
                background: theme.palette.primary.main,
                padding: theme.spacing(1, 2),
              }}
            />
            <CardContent>
              <Grid container justifyContent="flex-start" direction="column">
                <Typography
                  variant="body2"
                  sx={{ padding: theme.spacing(0, 0, 1) }}
                >
                  <Link
                    variant="body2"
                    color="primary"
                    href={`https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/docs/api/getting-started`}
                    target="_blank"
                    onClick={() => {
                      // Ensure gtag is defined
                      // @ts-ignore
                      if (typeof gtag !== "undefined") {
                        // @ts-ignore
                        gtag("event", "signup_intro_start", {
                          event_category: "signup",
                          event_label: "Signup Intro Start",
                          next: "api_docs",
                        });
                      }
                    }}
                    sx={{
                      padding: theme.spacing(0, 1, 0, 0),
                    }}
                  >
                    Setster API
                  </Link>
                  Read the docs to build a custom implementation.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ padding: theme.spacing(0, 0, 1) }}
                >
                  <Link
                    variant="body2"
                    color="primary"
                    href={`https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/docs/studio`}
                    target="_blank"
                    onClick={() => {
                      // Ensure gtag is defined
                      // @ts-ignore
                      if (typeof gtag !== "undefined") {
                        // @ts-ignore
                        gtag("event", "signup_intro_start", {
                          event_category: "signup",
                          event_label: "Signup Intro Start",
                          next: "studio_docs",
                        });
                      }
                    }}
                    sx={{
                      padding: theme.spacing(0, 1, 0, 0),
                    }}
                  >
                    Setster Studio
                  </Link>
                  Low-code toolset to get you started quicker
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ padding: theme.spacing(0, 0) }}
                >
                  <Link
                    variant="body2"
                    color="primary"
                    href="https://support.setster.com/hc/en-us/articles/114094041334-Adding-Booking-Widget-to-Website-or-Blog"
                    target="_blank"
                    onClick={() => {
                      // Ensure gtag is defined
                      // @ts-ignore
                      if (typeof gtag !== "undefined") {
                        // @ts-ignore
                        gtag("event", "signup_intro_start", {
                          event_category: "signup",
                          event_label: "Signup Intro Start",
                          next: "widget",
                        });
                      }
                    }}
                    sx={{
                      padding: theme.spacing(0, 1, 0, 0),
                    }}
                  >
                    Plug-n-Play Widget
                  </Link>
                  Add a booking experience to your website quickly.
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            elevation={0}
            variant="outlined"
            sx={{
              margin: theme.spacing(0, 0, 3, 0),
              backgroundColor: "#e1faf6",
            }}
          >
            <CardHeader
              title="Get Ready to Take Appointments"
              titleTypographyProps={{
                variant: "h6",
                color: theme.palette.text.primary,
                fontWeight: "bold",
              }}
              sx={{
                padding: theme.spacing(1, 2),
              }}
            />
            <CardContent>
              <Grid container justifyContent="flex-start" direction="column">
                <Typography
                  variant="body2"
                  sx={{ padding: theme.spacing(0, 0, 1) }}
                >
                  Before taking appointments you'll want to make sure you've
                  configured your account's booking details by adding your
                  locations, services, staff, and that you've made any needed
                  modifications to your booking form.
                </Typography>
                <Grid container direction={"row"} spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{ padding: theme.spacing(0, 0, 1) }}
                    >
                      <Link
                        variant="body2"
                        color="primary"
                        href={`https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/admin/settings/locations/`}
                        target="_blank"
                      >
                        Add Locations
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ padding: theme.spacing(0, 0, 1) }}
                    >
                      <Link
                        variant="body2"
                        color="primary"
                        href={`https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/admin/settings/services/`}
                        target="_blank"
                      >
                        Add Services
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ padding: theme.spacing(0, 0) }}
                    >
                      <Link
                        variant="body2"
                        color="primary"
                        href={`https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/admin/settings/staff/`}
                        target="_blank"
                      >
                        Add Staff
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {skipped === true && !subscribed ? (
            <Button
              type="button"
              onClick={goToPlans}
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-start",
                padding: theme.spacing(1),
                color: theme.palette.grey[500],
              }}
            >
              <NavigateBeforeIcon fontSize="small" />
              Choose a Plan{" "}
            </Button>
          ) : (
            <Link
              variant="body2"
              color="primary"
              href={`https://www.${process.env.REACT_APP_SETSTER_DOMAIN}/sales-appointment`}
              target="_blank"
              onClick={() => {
                // Ensure gtag is defined
                // @ts-ignore
                if (typeof gtag !== "undefined") {
                  // @ts-ignore
                  gtag("event", "signup_intro_start", {
                    event_category: "signup",
                    event_label: "Signup Intro Start",
                    next: "schedule_call",
                  });
                }
              }}
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-start",
                padding: theme.spacing(1),
              }}
            >
              Schedule Onboarding Call <NavigateNextIcon fontSize="small" />
            </Link>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IntroStart;
